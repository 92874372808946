<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation>
      <v-layout wrap>
        <v-flex xs12 md6 class="pa-2">
          <v-select
            :label="$t('Bids.SelectSender')"
            v-model="sender"
            :items="senders_list"
            :rules="[$root.rules.required]"
            item-text="text"
            item-value="value"
            hint="optional"
            :disabled="isSending"
            outlined dense
          ></v-select>
          <div style="margin-top:-20px;"></div>
        </v-flex>
        <v-flex xs12 md6 class="pa-2">
          <v-autocomplete
            dense outlined
            multiple hide-details
            :search-input.sync="recipientsSearch"
            @change="onRecipientChange"
            :disabled="isSending"
            v-model="recipients"
            :items="Object.keys(recipientsList)"
            :label="$t('Bids.EmailRecipient')"
            :rules="[$root.rules.required]"
            autocomplete="nope"
            :no-data-text="$t('TypeToSearch')"
          ></v-autocomplete>
          <!--<v-combobox
            dense multiple
            :disabled="isSending"
            v-model="recipients"
            @change="onRecipientChange"
            :items="recipientsList"
            :rules="[$root.rules.required]"
            outlined
            :label="$t('Bids.EmailRecipient')"
          ></v-combobox>-->
        </v-flex>
        <v-flex xs12 class="pa-2">
          <v-text-field dense :disabled="isSending" v-model="cc" outlined :label="$t('Bids.EmailCC')"></v-text-field>
          <div style="margin-top:-20px;"></div>
        </v-flex>
        <v-flex xs12 class="pa-2">
          <v-text-field v-model="subject"
                        :rules="[$root.rules.required]"
                        outlined dense
                        :disabled="isSending"
                        :label="$t('Bids.EmailSubject')"></v-text-field>
          <div style="background-color:#fff;color: #000;">
            <VueEditor v-model="body" :disabled="isSending" :editorToolbar="$root.customToolbar"></VueEditor>
          </div>
            <MultiFileUpload :field="replyAttachments"
                             :readonly="isSending"
                             :label="$t('Mails.AddAttachments')"
                             @change="onAttChange"
                             :key="loadDocumentsKey"
                             fieldName="replyAttachments"
                             uploadFolder="replyAttachments"
                             :showSizeWarning="true"
                             />
          <v-layout wrap>
            <v-flex grow :xs12="$root.isMobile" class="text-left">
              <v-checkbox
                :disabled="isSending"
                v-model="isGmailSignature"
                :label="$t('Bids.UseGmailSignature')">
              </v-checkbox>
            </v-flex>
            <v-flex shrink :xs12="$root.isMobile" class="text-center">
              <v-btn @click="attachFromLoadModal = true" :disabled="isSending">
                <v-icon left>mdi-dolly</v-icon>{{$t('AttachFilesFromLoad')}}</v-btn>
            </v-flex>
            <v-flex shrink :xs6="$root.isMobile" class="text-center">
              <v-btn @click="$emit('close')">{{$t('Cancel')}}</v-btn>
            </v-flex>
            <v-flex shrink :xs6="$root.isMobile" class="text-center">
              <v-btn @click="sendMessageReply" class="primary" :loading="isSending"><v-icon left>mdi-send</v-icon>{{$t('Reply')}}</v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-form>
    <v-dialog v-model="attachFromLoadModal" scrollable fullscreen persistent hide-overlay transition="dialog-bottom-transition">
      <v-card tile>
        <v-toolbar dark color="#015aaa" dense style="flex:0;">
          <v-btn icon dark @click="attachFromLoadModal = false;">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{$t('SelctedEmailAttFromTheLoad')}}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <SelectLoad @change="loadChanged"
                      :label="'select load'"
                      :hidePrependIcon="true"
          ></SelectLoad>
          <LoadDocumentsList @selected="onFilesFromLoadSelected"
                             :showAllFiles="true"
                             :fields="loadFields"
                             :key="loadDocumentsKey"></LoadDocumentsList>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" width="100%" @click="addSelectedFiles">{{$t('Select')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VueEditor, Quill } from 'vue2-editor';
import MultiFileUpload from './MultiFileUpload';
import LoadDocumentsList from './LoadDocumentsList';
import SelectLoad from './SelectLoad';

export default {
  name: 'ReplyForm',
  props: ['replySelectedSender', 'senders_list', 'replyBody', 'replyEmailRecipient', 'replyEmailCC', 'replyEmailSubject', 'msgId'],
  components: { LoadDocumentsList, MultiFileUpload, VueEditor, SelectLoad },
  data: function () {
    return {
      valid: true,
      isGmailSignature: true,
      replyAttachments: '[]',
      attachFromLoadModal: false,
      loadFields: {},
      loadDocumentsKey: 'laodDocumentsKey' + new Date().getTime(),
      selectedFilesFromLoad: [],
      subject: '',
      sender: '',
      recipients: [],
      cc: '',
      body: '',
      isSending: false,
      recipientsSearch: null,
      recipientsList: {}
    };
  },
  watch: {
    recipientsSearch (val) {
      if (!val || val.length === 0) return;
      this.$root.recipientsSearch(val).then(response => {
        let i = 0;
        if (response.status === 'ok') {
          console.error(this.replyEmailRecipient.length);
          for (i = 0; i < this.replyEmailRecipient.length; i++) {
            this.recipientsList[this.replyEmailRecipient[i]] = true;
          }
          for (i = 0; i < response.result.length; i++) {
            this.recipientsList[response.result[i]] = true;
          }
        }
      });
    }
  },
  mounted () {
    this.subject = this.replyEmailSubject;
    this.sender = this.replySelectedSender;
    this.recipients = this.replyEmailRecipient;

    for (let i = 0; i < this.replyEmailRecipient.length; i++) {
      this.recipientsList[this.replyEmailRecipient[i]] = true;
    }

    this.cc = this.replyEmailCC ? this.replyEmailCC : '';
    this.body = this.replyBody ? this.replyBody : '';
  },
  methods: {
    onAttChange (e) {
      this.replyAttachments = e;
    },
    onRecipientChange (e) {
    },
    loadChanged (e) {
      const loadId = parseInt(e);
      this.$root.getLoadDocuments(loadId).then((response) => {
        if (response.status === 'ok') {
          this.loadFields = response.results;
          this.loadDocumentsKey = 'laodDocumentsKey' + new Date().getTime();
        }
      });
    },
    onFilesFromLoadSelected (files) {
      this.selectedFilesFromLoad = files;
    },
    addSelectedFiles () {
      this.replyAttachments = JSON.stringify(this.selectedFilesFromLoad);
      this.attachFromLoadModal = false;
    },
    sendMessageReply () {
      if (!this.$refs.form.validate()) return;
      this.$dialog.confirm(this.$root.t('Bids.SendEmailConfirmationMsg', { 'email': this.sender })).then(dialog => {
        this.isSending = true;
        this.$root.sendReply(this.msgId,
          this.sender,
          this.recipients.join(','),
          this.cc,
          this.subject,
          this.body.replace(/<p><br><\/p>/img, '').replace(/(<p[^>]+?>|<p>)/img, '').replace(/<\/p>/img, '<br>').trim(),
          JSON.parse(this.replyAttachments),
          this.isGmailSignature).then(response => {
          if (response.status === 'ok') {
            // this.$root.toast(this.$i18n.t('EmailWasSent'), { color: 'success' });
            this.$dialog.alert(this.$i18n.t('EmailWasSent'), {
              okText: 'Ok'
            });
            this.$emit('change');
          } else if (response.status === 'error') {
            // this.$root.toast(response.msg, { color: 'error' });
            this.$dialog.alert(response.msg, {
              okText: 'Ok'
            });
          }
          this.isSending = false;
        });
      });
    }
  }
};
</script>
