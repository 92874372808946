<template>
  <div>
    <v-overlay :value="loading" :absolute="true" v-if="$route.name !== 'reportPrint'">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <DriversShiftsReportTemplate v-if="$route.name === 'reportPrint'"
                         :entries="$route.params.report_number > 0 ? null : shifts"
                         :totals="totalsCounts"
                         :reportData="reportData"
                         :from="from"
                         :to="to"
                         :driverName="driverName"
                         :driverId="driverId"
                         :currentDateTime="currentDateTime"
                         :companyId="companyId"
                         :key="'reportTemplateKey' + shifts.length"/>
    <v-card v-else>
      <v-card-title v-if="driverId > 0">
        <v-btn @click="$root.route('/reports/drivers')" icon><v-icon>mdi-chevron-left</v-icon></v-btn>
        <a @click="$root.onShowUserPreview(driverId, driverName)">{{driverName}}</a>
        <!--<v-spacer/><v-btn small @click="$emit('back-to-list')"><v-icon left small>mdi-view-list</v-icon> {{$t('BackDriversToList')}}</v-btn>--></v-card-title>

      <!--<v-tabs :value="0" v-if="!isShiftOnly" grow show-arrows active-class="tabs-active-color">
        <v-tab @click="$root.route('/reports/drivers' + ($route.params.item ? '/' + $route.params.item : ''))"><v-icon left>mdi-map-marker-path</v-icon>{{$t('Reports.DriverShifts')}}</v-tab>
        <v-tab @click="$root.route('/reports/driversLoads' + ($route.params.item ? '/' + $route.params.item : ''))"><v-icon left>mdi-dolly</v-icon>{{$t('Reports.DriverLoads')}}</v-tab>
      </v-tabs>-->

      <DriverTotals :from="from"
                    :to="to"
                    :key="from + to"
                    :driverId="driverId"/>
      <v-divider/>
      <template v-if="driverId > 0">
        <ReportActions :userId="driverId"
                       :overrideType="$store.getters.overriddenFieldsTypes.LOADS"
                       overrideFieldName="DriversSalary"
                       :from="from"
                       :to="to"
                       :paramsType="$route.params.type"
                       :selectedItems="selectedItems"
                       :isSelectedHaveNotPayed="isSelectedHaveNotPayed"
                       :isSelectedHavePayed="isSelectedHavePayed"
                       @create-report-group="createReport"
                       @mark-selected-as-paid="markSelectedAsPaid"
                       @mark-selected-as-not-paid="markSelectedAsNotPaid"
                       @mark-selected-as-payed-and-create-report-group="markSelectedAsPayedAndCreateReportGroup"
                       @paid-dt-change-multi="$emit('paid-dt-change-multi2', selectedItems, driverId, selectedItems[0].payedDateTime)"
                       @show-saved-reports="$root.onShowReportsModal"
        />
        <v-layout>
          <v-flex shrink>
            <FiltersPanel
              ref="filtersPanel"
              prefix="driversShiftsReport"
              outlined
              :isExpansionPanel="$root.isMobile"
              :loading="loading"
              :items="filtersItems"
              :key="filtersKey"
              @filterBrokerKeyUp="filterBrokerSearch"
              @change="onFilterChanged"/>
          </v-flex>
          <v-flex>
            <v-text-field
              class="mt-1"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Search"
              single-line
              outlined dense
              hide-details
            />
          </v-flex>
          <v-flex shrink v-if="$root.isMobile">
            <v-btn class="mt-1 ml-1"
                    @click="$refs.filtersPanel.showFiltersDrawer()">
                    <v-icon>mdi-filter</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <!--<v-layout v-if="$root.isFullFinancialDataAllowedToView && driverId > 0" wrap>
          <v-flex xs12 md6 class="text-center pl-1 pt-1">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" :disabled="selectedItems.length === 0">
                  Actions<v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                &lt;!&ndash;<v-list-item class="ma-1" :disabled="selectedItems.length === 0" @click="$emit('paidDTChangeMulti', selectedItems, driverId, selectedItems[0].paidDateTime)"><v-icon left>mdi-calendar-edit</v-icon> {{$t('Reports.EditPayedDateOnSelectedLoads')}}</v-list-item>
                <v-list-item class="ma-1" :disabled="selectedItems.length === 0" @click="markSelectedAsPayedAndCreateReportGroup(driverId, lastReportsGroupNumber + 1, $route.params.type, from, to, 'drivers_shifts')"><v-icon left>mdi-clipboard-check-multiple-outline</v-icon> {{$t('Reports.MarkAsPayedAndCreateReportsGroup')}}</v-list-item>&ndash;&gt;
                <v-list-item class="ma-1"
                             :disabled="!isSelectedHaveNotPayed"
                             @click="markSelectedAsPayed(driverId, $store.getters.overriddenFieldsTypes.SHIFTS, 'DriversSalary')">
                  <v-icon left>mdi-cash-check</v-icon>
                  {{$t('Reports.MarkSelectedAsPayed')}}
                </v-list-item>
                <v-list-item class="ma-1" :disabled="!isSelectedHavePayed" @click="markSelectedAsNotPayed(driverId, 'drivers_shifts')"><v-icon left>mdi-cash-remove</v-icon> {{$t('Reports.MarkSelectedAsNotPayed')}}</v-list-item>
              </v-list>
            </v-menu>
          </v-flex>
          <v-flex xs12 md6 :class="$root.isMobile ? 'text-center' : 'text-right'">
                <v-btn class="ma-1"
                       :disabled="selectedItems.length === 0"
                       color="success"
                       @click="createReportGroup(driverId, lastReportsGroupNumber + 1, $route.params.type, from, to)">
                  <v-icon left>mdi-clipboard-check-outline</v-icon> {{$t('Reports.CreateReportsGroup')}}
                </v-btn>
          </v-flex>
        </v-layout>-->
        <!--<hr/>
        {{loads}}
        <hr/>-->
        <v-list v-if="$root.isMobile">
          <v-list-item-group
            v-model="selectedItems"
            multiple
            active-class=""
          >
            <template v-for="item in shifts">
              <v-list-item :key="item.id"  :value="item">
                <template v-slot:default="{ active }">
                  <v-list-item-icon>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-icon>
                  <v-list-item-content @click="onRowClick(item)">
                    <v-list-item-subtitle>
                      <template v-for="shiftLoadId in Object.keys(item.shiftLoads)">
                        <a :key="'shiftLoadId' + shiftLoadId" @click.stop="$root.onShowUserPreview(item.shiftLoads[shiftLoadId].dispatcher, item.shiftLoads[shiftLoadId].dispatcherName)">{{item.shiftLoads[shiftLoadId].dispatcherName}}</a>&nbsp;
                      </template>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>{{item.startedPST}}</v-list-item-subtitle>
                    <v-list-item-title>
                      <div class="text-no-wrap" style="max-width: 300px; text-overflow: ellipsis; overflow: hidden;">{{item.startLocation}}</div>
                      <div class="text-no-wrap" style="max-width: 300px; text-overflow: ellipsis; overflow: hidden;">{{item.endLocation}}</div>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{item.endedPST}}</v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <div>
                        {{$root.moneyValue(item.isShift ? item.shiftRate : item.loadsOdometerRate, 2)}}
                        <template v-if="item.driverDetention">+{{$root.moneyValue(item.driverDetention, 2)}}</template>
                        <template v-if="item.shiftToCompensate">+{{$root.moneyValue(item.shiftToCompensate, 2)}}</template>
                        <template v-if="item.shiftBonuses">+{{$root.moneyValue(item.shiftBonuses, 2)}}</template>
                        <template v-if="item.shiftDeductions">-{{$root.moneyValue(item.shiftDeductions, 2)}}</template>
                      </div>
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      <div v-if="item.isPaid" class="text-left">
                        <v-layout>
                          <v-flex>
                            <v-chip class="ma-1" small color="success">
                              <v-icon small left @click.stop="onPaid(item.id, driverId, 'DriversSalary', item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS, item.paidAmount, item.paidDateTime, item.paidNote)">mdi-pencil</v-icon>
                              {{$t('Reports.Paid')}}&nbsp;${{parseInt(item.paidAmount).toLocaleString()}}
                              <v-icon small right color="error" @click.stop="resetPayment(driverId, item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS, item.id, 'DriversSalary')">mdi-close</v-icon>
                            </v-chip>
                          </v-flex>
                          <v-flex v-if="item.isPaid && item.paidNote">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" small style="margin-top: 2px;"><v-icon>mdi-information</v-icon></v-btn>
                              </template>
                              <span>{{item.paidNote}}</span>
                            </v-tooltip>
                          </v-flex>
                        </v-layout>
                        <div class="text-no-wrap">
                          <label class="caption text-no-wrap">{{item.paidDateTime}}</label>
                        </div>
                      </div>
                      <v-layout v-if="!item.isPaid" class="text-left">
                        <v-flex>
                          {{$root.moneyValue(item.toPayAmount, 2)}}
                        </v-flex>
                        <v-flex class="text-right pt-0" v-if="item.isVerified">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on"
                                    class="mr-1 mb-1"
                                    x-small
                                    @click.stop="onPaid(item.id, driverId, 'DriversSalary', item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS, (item.isShift ? item.shiftRate : item.loadsOdometerRate) +  parseFloat(item.driverDetention || 0) + parseFloat(item.shiftToCompensate || 0) - parseFloat(item.shiftDeductions || 0) + parseFloat(item.shiftBonuses || 0), '')">
                                <v-icon small left color="success">mdi-currency-usd</v-icon>{{$t('Reports.Paid')}}
                              </v-btn>
                            </template>
                            <span>Mark as Paid</span>
                          </v-tooltip>
                        </v-flex>
                      </v-layout>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    {{parseInt(item.isShift ? item.shiftDistance : item.loadsOdometerDistance).toLocaleString()}}&nbsp;{{$t('milesShort')}}<br/>
                    ${{Object.keys(item.driverRates).join(',$')}}<br/>
                    <v-layout>
                      <v-flex shrink class="pt-1">
                        <template>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn  v-on="on"
                                      :loading="item.loading"
                                      :disabled="!item.isChecked"
                                      @click.stop="onReportToggleChecked(item, item.id, driverId, item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS, 100)"
                                      icon>
                                <v-icon :color="item.isVerified ? 'success': null">mdi-check-all</v-icon>
                              </v-btn>
                            </template>
                            <span>
                            {{item.isVerified ? ($t('Reports.MarkedAsVerified') + (item.verifiedDateTime ? ': ' + item.verifiedDateTime : '')) : $t('Reports.MarkAsVerified')}}
                          </span>
                          </v-tooltip>
                        </template>
                      </v-flex>
                      <v-flex shrink class="pt-1">
                        <template>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn  v-on="on"
                                      :loading="item.loading"
                                      @click.stop="onReportToggleChecked(item, item.id, driverId, item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS)"
                                      icon>
                                <v-icon :color="item.isChecked ? 'success': null">mdi-check-decagram</v-icon>
                              </v-btn>
                            </template>
                            <span>
                            {{item.isChecked ? ($t('Reports.MarkedAsChecked') + (item.checkedDateTime ? ': ' + item.checkedDateTime : '')) : $t('Reports.MarkAsChecked')}}
                          </span>
                          </v-tooltip>
                        </template>
                      </v-flex>
                    </v-layout>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>

        <v-data-table v-else
          :headers="headers"
          :items="shifts"
          :search="search"
          :items-per-page="-1"
          :hide-default-footer="true"
          v-model="selectedItems"
          fixed-header
          show-select
          @item-selected="onItemSelected"
          @toggle-select-all="onItemSelected"
          :single-expand="false"
          @click:row="onRowClick($event)"
        >
          <!--<template v-slot:top>
              <v-subheader>
                <v-spacer/>
                <v-icon left>mdi-map</v-icon>{{$t('ByMaps')}}
                <v-icon left class="ml-2">mdi-counter</v-icon>{{$t('ByOdometer')}}
              </v-subheader>
          </template>-->
          <template v-slot:item.status="{ item }">
            <v-icon color="primary" v-if="item.isOpen === 1">mdi-truck-fast</v-icon>
            <v-icon color="success" v-else>mdi-check</v-icon>
            <v-icon v-if="!item.isShift">mdi-dolly</v-icon>
          </template>

          <template v-slot:item.load="{ item }">
            <template v-for="shiftLoadId in Object.keys(item.shiftLoads)">
              <a @click.stop="$root.onShowLoadPreview(shiftLoadId)" :key="'shiftLoad' + shiftLoadId">#{{shiftLoadId}}</a>&nbsp;
            </template>
          </template>

          <template v-slot:item.dispatcher="{ item }">
            <v-layout>
              <v-flex xs12  class="text-center">
                <template v-for="shiftLoadId in Object.keys(item.shiftLoads)">
                  <v-tooltip bottom :key="'shiftLoadDispatcher'+shiftLoadId">
                    <template v-slot:activator="{ on }">
                      <v-avatar v-on="on"
                                style="cursor: pointer;"
                                class="ml-1"
                                :size="24"
                                @click.stop="$root.onShowUserPreview(item.shiftLoads[shiftLoadId].dispatcher, item.shiftLoads[shiftLoadId].dispatcherName)"
                                :color="(item.shiftLoads[shiftLoadId].dispatcherInitials && !item.shiftLoads[shiftLoadId].dispatcherAvatar)?'#015aaa':null">
                        <v-img :src="$root.getImageUrl('avatar', item.shiftLoads[shiftLoadId].dispatcherAvatar)" v-if="item.shiftLoads[shiftLoadId].dispatcherAvatar"></v-img>
                        <span class="white--text" v-else-if="item.shiftLoads[shiftLoadId].dispatcherInitials">{{item.shiftLoads[shiftLoadId].dispatcherInitials}}</span>
                        <v-icon large v-else>account_circle</v-icon>
                      </v-avatar>
                    </template>
                    <span>{{item.shiftLoads[shiftLoadId].dispatcherName}}</span>
                  </v-tooltip>
                </template>
              </v-flex>
            </v-layout>
          </template>

          <template v-slot:item.trucks="{ item }">
            <template v-for="(truck, index) in item.trucksDistinctList">
              <a href="#" class="block" @click.stop="$root.onShowTruckPreview(truck.truckId, truck.truckName)" :key = "truck.truckName + '_' + index">{{truck.truckName}}</a>
              <br :key="truck.truckName + '_' + index + '_br'"/>
            </template>
          </template>

          <template v-slot:item.driver="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-avatar v-on="on"
                          style="cursor: pointer;"
                          class="ml-1"
                          :size="24"
                          @click.stop="$root.onShowUserPreview(item.driverId, item.driverFullName)"
                          :color="(item.driverInitials && !item.driverAvatarUPLOAD)?'#015aaa':null">
                  <v-img :src="$root.getImageUrl('avatar', item.driverAvatarUPLOAD)" v-if="item.driverAvatarUPLOAD"></v-img>
                  <span class="white--text" v-else-if="item.driverInitials">{{item.driverInitials}}</span>
                  <v-icon large v-else>account_circle</v-icon>
                </v-avatar>
              </template>
              <span>{{item.driverFullName}}</span>
            </v-tooltip>
          </template>


          <template v-slot:item.started="{ item }">
            <div class="text-no-wrap">{{item.startedPST}}</div>
            <div class="text-no-wrap">{{item.endedPST}}</div>
          </template>

          <template v-slot:item.origin="{ item }">
            <v-layout>
              <v-flex shrink>
                <LoadLocationsTooltip :load-fields="item"/>
              </v-flex>
              <v-flex grow>
                <v-layout>
                  <v-flex grow>
                    <div class="text-no-wrap" style="max-width: 200px; text-overflow: ellipsis; overflow: hidden;">{{item.startLocation}}</div>
                  </v-flex>
                  <v-flex shrink>
                    <v-btn v-if="item.startLocation" small icon @click.stop="$root.copyToClipboard(item.startLocation)"><v-icon small>mdi-content-copy</v-icon></v-btn>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex grow>
                    <div class="text-no-wrap" style="max-width: 200px; text-overflow: ellipsis; overflow: hidden;">{{item.endLocation}}</div>
                  </v-flex>
                  <v-flex shrink>
                    <v-btn v-if="item.endLocation" small icon @click.stop="$root.copyToClipboard(item.endLocation)"><v-icon small>mdi-content-copy</v-icon></v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex shrink class="">
                <v-btn icon small @click.stop="$root.openGmapsDirections(item)"><v-icon small>mdi-directions</v-icon></v-btn><br/>
                <v-btn icon small @click.stop="$emit('show-map-modal', item)"
                                  @mouseenter="(event) => { $emit('show-map-modal',item, true, event) }"
                                  @mouseout="() => { $root.floatingMap = false; }">
                  <v-icon small @mouseenter="(event) => { $emit('show-map-modal',item, true, event) }">mdi-map</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </template>

          <template v-slot:item.driverDetentionRate="{ item }">
            <v-layout>
              <v-flex shrink class="pt-1">
                {{item.driverDetentionFormatted}}
              </v-flex>
              <v-flex shrink v-if="Object.keys(item.shiftLoads).length === 1">
                <OverriddenFieldsButton
                  :key="$root.overrideFieldKey"
                  :userId="driverId"
                  :itemType="item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS"
                  :itemId="item.id"
                  prefix="hh:mm"
                  :isTime="true"
                  fieldName="driverDetentionMinutes"
                  :currentNote="item.driverDetentionMinutesNote"
                  :currentValue="item.driverDetentionMinutes"
                  :showCancel="item.isOverriddenDriverDetentionMinutes"
                  @change="fetchData"
                />
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex shrink class="pt-1">
                ${{parseFloat(item.driverDetention.toFixed(2)).toLocaleString()}}
              </v-flex>
              <v-flex shrink v-if="Object.keys(item.shiftLoads).length === 1">
                <OverriddenFieldsButton
                  :key="$root.overrideFieldKey"
                  :userId="driverId"
                  :itemType="item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS"
                  :itemId="item.id"
                  prefix="$"
                  fieldName="driversDetentionRate"
                  :currentNote="item.driversDetentionRateNote"
                  :currentValue="item.driverDetention"
                  :showCancel="item.isOverriddenDriverDetention"
                  @change="fetchData"
                />
              </v-flex>
            </v-layout>
          </template>

          <template v-slot:item.shiftDuration="{ item }">
            <v-layout>
              <v-flex shrink class="pt-1">
                {{$root.showDuration(item.shiftDuration * 60, true)}}
              </v-flex>
              <v-flex shrink v-if="Object.keys(item.shiftLoads).length === 1">
                <OverriddenFieldsButton
                  :key="$root.overrideFieldKey"
                  :userId="driverId"
                  :itemType="item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS"
                  :itemId="item.id"
                  prefix="hh:mm"
                  :isTime="true"
                  fieldName="driversShiftDuration"
                  :currentNote="item.shiftDurationNote"
                  :currentValue="item.shiftDuration"
                  :showCancel="item.isOverriddenShiftDuration"
                  @change="fetchData"
                />
              </v-flex>
            </v-layout>
          </template>

          <template v-slot:item.distance="{ item }">
            <template v-if="!item.isShift"><v-icon left small>mdi-map</v-icon>{{parseInt(item.loadsMapDistance).toLocaleString()}}&nbsp;{{$t('milesShort')}}<br/></template>
            <v-layout>
              <v-flex shrink class="pt-1">
                <v-icon left small>mdi-counter</v-icon>
              </v-flex>
              <v-flex shrink class="mt-1">
                {{parseInt(item.isShift ? item.shiftDistance : item.loadsOdometerDistance).toLocaleString()}}&nbsp;{{$t('milesShort')}}
              </v-flex>
              <v-flex shrink v-if="Object.keys(item.shiftLoads).length === 1">
                <OverriddenFieldsButton
                  :key="$root.overrideFieldKey"
                  :userId="driverId"
                  :itemType="item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS"
                  :itemId="item.id"
                  prefix="mi."
                  fieldName="driversShiftDistance"
                  :currentNote="item.driversShiftDistanceNote"
                  :currentValue="item.isShift ? item.shiftDistance : item.loadsOdometerDistance"
                  :showCancel="item.isOverriddenLoadsOdometerDistance"
                  @change="fetchData"
                />
              </v-flex>
            </v-layout>
          </template>

          <template v-slot:item.distance_adv="{ item }">
            <template v-if="Math.abs(item.loadsMapDistance - (item.isShift ? item.shiftDistance : item.loadsOdometerDistance)) > 0">
              <v-chip x-small :class="item.loadsMapDistance - (item.isShift ? item.shiftDistance : item.loadsOdometerDistance) >= 0 ? 'ml-1 success' : 'ml-1 error'">
                <template v-if="item.isShift">
                  <template v-if="item.loadsMapDistance - item.shiftDistance >= 0">-</template>
                  <template v-else>+</template>
                  {{Math.abs(parseInt(item.loadsMapDistance - item.shiftDistance)).toLocaleString()}}&nbsp;{{$t('milesShort')}}
                </template>
                <template v-else>
                  <template v-if="item.loadsMapDistance - item.loadsOdometerDistance >= 0">-</template>
                  <template v-else>+</template>
                  {{Math.abs(parseInt(item.loadsMapDistance - item.loadsOdometerDistance)).toLocaleString()}}&nbsp;{{$t('milesShort')}}
                </template>
              </v-chip>
            </template>
          </template>

          <!--<template v-slot:item.rate="{ item }">
            <template v-if="!item.isShift"><v-icon left small>mdi-map</v-icon>${{parseFloat(parseFloat(item.loadsMapRate).toFixed()).toLocaleString()}}<br/></template>
            <v-layout>
              <v-flex shrink class="mt-1 text-no-wrap">
                <v-icon left small>mdi-counter</v-icon>
              </v-flex>
              <v-flex shrink class="mt-1">
                ${{item.isShift ? parseFloat(parseFloat(item.shiftRate).toFixed()).toLocaleString() : parseFloat(parseFloat(item.loadsOdometerRate).toFixed()).toLocaleString()}}
              </v-flex>
              <v-flex shrink>
                <OverriddenFieldsButton
                  :key="$root.overrideFieldKey"
                  :userId="driverId"
                  :itemType="item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS"
                  :itemId="item.id"
                  prefix="$"
                  fieldName="driversShiftRate"
                  :currentNote="item.driversShiftRateNote"
                  :currentValue="item.isShift ? item.shiftRate : item.loadsOdometerRate"
                  :showCancel="item.isOverriddenLoadsOdometerRate"
                  @change="fetchData"
                />
              </v-flex>
            </v-layout>
          </template>-->

          <template v-slot:item.driverCompensations="{ item }">
            ${{parseFloat(item.shiftToCompensate.toFixed(2)).toLocaleString()}}
          </template>
          <template v-slot:item.driverDeductions="{ item }">
            ${{parseFloat(item.shiftDeductions.toFixed(2)).toLocaleString()}}
          </template>
          <template v-slot:item.driverBonuses="{ item }">
            ${{parseFloat(item.shiftBonuses.toFixed(2)).toLocaleString()}}
          </template>

          <template v-slot:item.ratePerMile="{ item }">
            ${{Object.keys(item.driverRates).join(',$')}}
            <!--<template v-if="!item.isShift && item.loadsMapDistance"><v-icon left small>mdi-map</v-icon>${{parseFloat(parseFloat(item.loadsMapRate/item.loadsMapDistance).toFixed(2)).toLocaleString()}}<br/></template>
            <template v-else-if="!item.isShift"><v-icon left small>mdi-map</v-icon>$0.0<br/></template>
            <template v-if="item.isShift && item.shiftDistance">
              <v-icon left small>mdi-counter</v-icon>${{parseFloat(parseFloat(item.shiftRate/item.shiftDistance).toFixed(2)).toLocaleString()}}
            </template>
            <template v-else-if="item.loadsOdometerDistance">
              <v-icon left small>mdi-counter</v-icon>${{parseFloat(parseFloat(item.loadsOdometerRate/item.loadsOdometerDistance).toFixed(2)).toLocaleString()}}
            </template>
            <template v-else><v-icon left small>mdi-counter</v-icon>$0.0</template>-->
          </template>


          <template v-slot:item.toPayAmount="{ item }">
            <div v-if="item.isPaid"
                 class="text-center">
              <v-layout>
                <v-flex>
                  <v-chip class="ma-1" small color="success">
                    <v-icon small left @click.stop="onPaid(item.id, driverId, 'DriversSalary', item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS, item.paidAmount, item.paidDateTime, item.paidNote)">mdi-pencil</v-icon>
                    {{$t('Reports.Paid')}}&nbsp;${{parseInt(item.paidAmount).toLocaleString()}}
                    <v-icon small right color="error" @click.stop="resetPayment(driverId, item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS, item.id, 'DriversSalary')">mdi-close</v-icon>
                  </v-chip>
                </v-flex>
                <v-flex v-if="item.isPaid && item.paidNote">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" small style="margin-top: 2px;"><v-icon>mdi-information</v-icon></v-btn>
                    </template>
                    <span>{{item.paidNote}}</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
              <div class="text-no-wrap">
                <label class="caption text-no-wrap">{{item.paidDateTime}}</label>
              </div>
            </div>
            <v-layout v-if="!item.isPaid" class="text-center">
              <v-flex>
                ${{item.toPayAmount.toLocaleString()}}
              </v-flex>
              <v-flex class="text-right pt-0" v-if="item.isVerified">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on"
                           class="ml-1"
                           x-small
                           @click.stop="onPaid(item.id, driverId, 'DriversSalary', item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS, (item.isShift ? item.shiftRate : item.loadsOdometerRate) +  parseFloat(item.driverDetention || 0) + parseFloat(item.shiftToCompensate || 0) - parseFloat(item.shiftDeductions || 0) + parseFloat(item.shiftBonuses || 0), '')">
                      <v-icon small left color="success">mdi-currency-usd</v-icon>{{$t('Reports.Paid')}}
                    </v-btn>
                  </template>
                  <span>Mark as Paid</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-layout>
              <v-flex shrink class="pt-1">
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn  v-on="on"
                              :loading="item.loading"
                              :disabled="!item.isChecked"
                              @click.stop="onReportToggleChecked(item, item.id, driverId, item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS, 100)"
                              icon
                              small>
                        <v-icon :color="item.isVerified ? 'success': null">mdi-check-all</v-icon>
                      </v-btn>
                    </template>
                    <span>
                    {{item.isVerified ? ($t('Reports.MarkedAsVerified') + (item.verifiedDateTime ? ': ' + item.verifiedDateTime : '')) : $t('Reports.MarkAsVerified')}}
                  </span>
                  </v-tooltip>
                </template>
              </v-flex>
              <v-flex shrink class="pt-1">
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn  v-on="on"
                              :loading="item.loading"
                              @click.stop="onReportToggleChecked(item, item.id, driverId, item.isShift ? $store.getters.overriddenFieldsTypes.SHIFTS : $store.getters.overriddenFieldsTypes.LOADS)"
                              icon
                              small>
                        <v-icon small :color="item.isChecked ? 'success': null">mdi-check-decagram</v-icon>
                      </v-btn>
                    </template>
                    <span>
                    {{item.isChecked ? ($t('Reports.MarkedAsChecked') + (item.checkedDateTime ? ': ' + item.checkedDateTime : '')) : $t('Reports.MarkAsChecked')}}
                  </span>
                  </v-tooltip>
                </template>
              </v-flex>
              <template v-if="item.loads && Object.keys(item.loads).length === 0">
                <v-flex>No Loads</v-flex>
              </template>
              <template v-else-if="item.loads && Object.keys(item.loads).length > 1">
                <v-flex>Multiply Loads<br/>Click For Details</v-flex>
              </template>
              <template v-else-if="item.loads && Object.keys(item.loads).length === 1">
                <v-flex class="pa-0 pt-1" v-if="item.notes" shrink>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon small v-on="on" @click.stop="">
                        <v-icon small color="warning">mdi-alert</v-icon>
                      </v-btn>
                    </template>
                    <span>{{item.notes}}</span>
                  </v-tooltip>
                </v-flex>
                <template v-for="load in Object.values(item.loads)">
                  <v-flex v-for="invoiceItem in load.invoicesArray"
                          shrink
                          class="pa-0"
                          :key="'inv'+invoiceItem.id">
                    <template v-if="invoiceItem.invoiceReceivedDate > 0 && $root.isInvoicesViewAllowed">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on"
                                 :color="$root.getInvoiceTypeColorByStatus(invoiceItem.invoiceStatus)"
                                 @click.stop="$root.onShowInvoicePreview(invoiceItem.invoiceId, invoiceItem.invoiceNumber)"><v-icon small>fa-file-invoice-dollar</v-icon></v-btn>
                        </template>
                        <span>{{$t('Loads.Invoice')}} #{{invoiceItem.invoiceNumber}} ${{invoiceItem.invoiceTotal}}&nbsp;<strong>{{invoiceItem.invoiceStatus}}</strong></span>
                      </v-tooltip>
                    </template>
                    <template v-else-if="!invoiceItem.invoiceReceivedDate && $root.isInvoicesViewAllowed">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on"
                                :color="invoiceItem.invoiceDueLeftSeconds < 0 ? 'red' : 'warning'"
                                 @click.stop="$root.onShowInvoicePreview(invoiceItem.invoiceId, invoiceItem.invoiceNumber)"><v-icon small>fa-file-invoice-dollar</v-icon></v-btn>
                        </template>
                        <span v-if="invoiceItem.invoiceDueLeftSeconds < 0">{{$t('Loads.Invoice')}} #{{invoiceItem.invoiceNumber}} ${{invoiceItem.invoiceTotal}}&nbsp;{{$t('Invoices.Overdue')}}:&nbsp;-{{Math.ceil($root.secondsToHms(invoiceItem.invoiceDueLeftSeconds).hours / 24)}} d.</span>
                        <span v-if="invoiceItem.invoiceDueLeftSeconds > 0">{{$t('Loads.Invoice')}} #{{invoiceItem.invoiceNumber}} ${{invoiceItem.invoiceTotal}}&nbsp;{{$t('Invoices.DueDate')}}:&nbsp;+{{Math.ceil($root.secondsToHms(invoiceItem.invoiceDueLeftSeconds).hours / 24)}} d.</span>
                      </v-tooltip>
                    </template>
                  </v-flex>
                  <v-flex shrink v-if="!load.invoicesArray || load.invoicesArray.length === 0">
                    <v-btn icon disabled><v-icon small class="mr-1">fa-file-invoice-dollar</v-icon></v-btn>
                  </v-flex>
                  <v-flex shrink>
                    <template>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on" @click.stop="onShowDocuments(load)">
                            <v-icon small class="mr-1" :color="item.isAllRequiredDocsUploaded ? 'success' : 'warning'">mdi-file-multiple</v-icon>
                          </v-btn>
                        </template>
                        <span v-if="item.isAllRequiredDocsUploaded">{{$t('Loads.uploadDocuments')}}</span>
                        <span v-else>
                          <template v-for="(md, index) in item.missingDocs">
                            <span :key="'missingDocs_'+item.id+index" v-if="index === 'loadPhotos'">{{$t('Loads.LoadPhotos')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                            <span :key="'missingDocs_'+item.id+index" v-if="index === 'rateCon'">{{$t('Loads.RateCon')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                            <span :key="'missingDocs_'+item.id+index" v-if="index === 'do'">{{$t('Loads.DeliveryOrder')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                            <span :key="'missingDocs_'+item.id+index" v-if="index === 'pod'">{{$t('Loads.POD')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                            <span :key="'missingDocs_'+item.id+index" v-if="index === 'bol'">{{$t('Loads.BOL')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}</span>
                          </template>
                        </span>
                      </v-tooltip>
                    </template>
                  </v-flex>
                  <v-flex shrink>
                    <template>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on" @click.stop="onShowExpenses(load.expensesJSON, load.finished, load.id, driverId, item.trucksDistinctList)">
                            <v-icon small class="mr-1" :color="expensesColor(load, driverId, 0)">fa-coins</v-icon>
                          </v-btn>
                        </template>
                        <span>{{!load.isPendingExpenses ? $t('Loads.ExpensesTitle') : $t('Loads.ExpensesHasPending')}}</span>
                      </v-tooltip>
                    </template>
                  </v-flex>
                  <v-flex shrink class="pt-1">
                    <template>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn small icon v-on="on" @click.stop="onShowFinancialData(load)">
                            <v-icon small class="mr-1">mdi-finance</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t('Loads.FinancialData')}}</span>
                      </v-tooltip>
                    </template>
                  </v-flex>
                </template>
              </template>
            </v-layout>
          </template>
        </v-data-table>
      </template>
    </v-card>

    <v-dialog v-model="shiftModal" hide-overlay fullscreen scrollable transition="dialog-bottom-transition">
      <v-card tile>
        <v-toolbar dark :color="$root.currentTheme.primary" dense fixed style="flex:0;">
          <v-toolbar-items>
            <v-btn icon dark @click="() => { shiftModalKey = new Date().getTime(); shiftModal = false; }"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar-items>
          <v-toolbar-title class="text-no-wrap">
            {{$t('Shifts.Shift')}}
          </v-toolbar-title>
          <v-spacer/>
          <v-toolbar-title>
            <v-btn icon @click="refreshShiftModal"><v-icon>mdi-reload</v-icon></v-btn>
          </v-toolbar-title>
        </v-toolbar>
          <!--<DriverRateBreakdown :data="selectedItem.rateBreakdown" :key="'DriverRateBreakdown' + shiftModalKey"/>-->
          <ShiftTrucksAndLoads :item="selectedItem"
                               :loads="selectedItem.loads"
                               :key="'ShiftTrucksAndLoads' + shiftModalKey"
                               :driverId="driverId"
                               @fetch="refreshShiftModal"
                               @on-show-expenses="onShowExpenses"
                               @on-show-documents="onShowDocuments"
                               @on-show-financial-data="onShowFinancialData"
                               @show-map-modal= "(item, floating, event) => $emit('show-map-modal', item, floating, event)"
          />
      </v-card>
    </v-dialog>

    <v-dialog v-model="expensesModal" scrollable max-width="1000px">
      <v-card>
        <v-card-title>
          <h2>{{$t('Loads.ExpensesTitle')}}</h2><v-spacer></v-spacer><v-btn icon @click="expensesModal=false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <LoadExpenses :key="expensesModalKey"
                      :expensesArray="selectedExpenses"
                      :readOnly="!$root.isLoadsEditAllowed"
                      :isFinished="selectedFinishedField !== 0"
                      :loadId="selectedExpensesLoadId"
                      :preselectedTruck="selectedExpensesTruckId"
                      :preselectedDriver="selectedExpensesUserId"
                      :filterByDriverId="selectedExpensesUserId"
                      @expensesSaved="() => { fetchData(); $emit('fetch'); }"
        />
      </v-card>
    </v-dialog>
    <v-dialog v-model="documentsModal" scrollable>
      <v-card>
        <v-card-title>
          <h2>{{$t('Loads.uploadDocuments')}}</h2><v-spacer></v-spacer><v-btn icon @click="documentsModal=false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <LoadDocuments :key="documentsModalKey" :fields="selectedLoadFields" :readonly="true" :loadId="selectedLoadFields.id" :allowUploadDriverReports="true"></LoadDocuments>
      </v-card>
    </v-dialog>
    <v-dialog v-model="financesModal" scrollable max-width="1000px">
      <v-card>
        <v-card-title>
          <h2>{{$t('Loads.FinancialData')}}</h2><v-spacer></v-spacer><v-btn icon @click="financesModal=false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <FinancialDataForLoad :loadId="selectedFinancesLoadId"
                              :expenses="selectedExpenses"
                              :key="financesModalKey"
                              :locations="selectedLoadLocations"
                              :isFullFinancialData="$root.isFullFinancialDataAllowedToView"
                              :isDispatcherFinancialData="$root.isDispatcherFinancialDataAllowedToView"
                              :isDriverFinancialData="$root.isDriverFinancialDataAllowedToView"
                              :excludeDispatcherPayment="parseInt(selectedLoadExcludeDispatcherPayment) === 1 ? true : false"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import reportsMixin from '../../mixins/reportsMixin.js';
import DriversShiftsReportTemplate from './DriversShiftsReportTemplate.vue';
import ShiftTrucksAndLoads from './../Shifts/ShiftTrucksAndLoads.vue';
import DriverRateBreakdown from '../../components/DriverRateBreakdown';
import OverriddenFieldsButton from '../../components/OverriddenFieldsButton';
import LoadExpenses from '../../components/LoadExpenses.vue';
import LoadDocuments from '../../components/LoadDocuments.vue';
import FinancialDataForLoad from '../../components/FinancialDataForLoad.vue';
import ReportActions from '../../components/ReportActions';
import FiltersPanel from '../../components/FiltersPanel';
import LoadLocationsTooltip from '../../components/LoadLocationsTooltip';
import DriverTotals from './DriverTotals';

export default {
  name: 'DriverShiftsReport',
  props: ['from', 'to', 'driverId', 'driverName', 'selectedStr', 'isShiftOnly', 'hideReportsGroups', 'reportData', 'type', 'companyId'],
  components: { DriverTotals, ReportActions, DriverRateBreakdown, DriversShiftsReportTemplate, ShiftTrucksAndLoads, OverriddenFieldsButton, LoadExpenses, LoadDocuments, FinancialDataForLoad, FiltersPanel, LoadLocationsTooltip },
  mixins: [reportsMixin],
  data: function () {
    return {
      loading: false,
      isMounted: false,
      shifts: [],
      selectedItems: [],
      selectedItem: {},
      search: '',
      shiftModal: false,
      shiftModalKey: new Date().getTime(),
      milesDiffHighlightThreshold: 30,
      expanded: [],
      headers: [
        { text: '', value: 'status' },
        { text: this.$root.t('Shifts.Load'), value: 'load', sortable: false },
        { text: this.$root.t('Shifts.DispatcherShort'), value: 'dispatcher', sortable: false },
        { text: this.$root.t('Shifts.Trucks'), value: 'trucks', sortable: false },
        { text: this.$root.t('Shifts.Started') + '/' + this.$root.t('Shifts.Ended'), value: 'started', sortable: false },
        { text: this.$root.t('Shifts.Origin') + '/' + this.$root.t('Shifts.Finish'), value: 'origin', sortable: false },
        { text: this.$root.t('Shifts.Duration'), value: 'shiftDuration', sortable: true },
        { text: this.$root.t('Shifts.Distance'), value: 'distance', sortable: false },
        { text: 'Diff', value: 'distance_adv', sortable: false },
        { text: this.$root.t('Shifts.RatePerMile'), value: 'ratePerMile', sortable: false },
        { text: this.$root.t('Shifts.DriversDetention'), value: 'driverDetentionRate', sortable: true },
        { text: this.$root.t('Shifts.CompensationsShort'), value: 'driverCompensations', sortable: true },
        { text: this.$root.t('Shifts.DeductionsShort'), value: 'driverDeductions', sortable: true },
        { text: this.$root.t('Shifts.Bonuses'), value: 'driverBonuses', sortable: true },
        { text: this.$root.t('Shifts.Salary'), value: 'toPayAmount', sortable: true },
        { text: '', value: 'paid', sortable: false },
        { text: '', value: 'actions', sortable: false }
      ],
      expensesModal: false,
      expensesModalKey: new Date().getTime(),
      selectedExpenses: [],
      selectedExpensesLoadId: 0,
      selectedExpensesUserId: 0,
      selectedExpensesTruckId: 0,
      documentsModal: false,
      documentsModalKey: new Date().getTime(),
      selectedLoadFields: {},
      financesModal: false,
      financesModalKey: new Date().getTime(),
      selectedLoadLocations: [],
      selectedFinancesLoadId: 0,
      selectedFinishedField: 0,
      selectedLoadExcludeDispatcherPayment: 0
    };
  },
  computed: {
    totalsCounts () {
      return this.$store.getters['reports/totalsCounts'];
    }
  },
  async mounted () {
    this.$set(this, 'filtersItems', this.filtersItems.filter((el) => { return el.name !== 'filterDriver'; }));
    this.setDriversTotalsHeaders();

    if (this.type) {
      this.$route.params.type = this.type;
    }
    this.$root.setLastListPath(this.$route.path);
    this.$root.overrideFieldCallback = this.fetchData;
    this.$root.getGlobalSettingsField('milesDiffHighlightThreshold').then((response) => {
      if (response.status === 'ok') {
        this.milesDiffHighlightThreshold = parseInt(response.result);
      }
    });
    await this.fetchData();
    this.reportTitle();
  },
  methods: {
    refreshShiftModal () {
      let i = 0;
      let id = 0;
      this.fetchData().then(() => {
        if (this.selectedItem.hasOwnProperty('id')) {
          id = parseInt(this.selectedItem.id);
          for (i = 0; i < this.shifts.length; i++) {
            if (parseInt(this.shifts[i].id) === id) {
              this.$set(this, 'selectedItem', this.shifts[i]);
              break;
            }
          }
        }
        this.$nextTick(() => {
          this.shiftModalKey = new Date().getTime();
        });
      });
    },
    onShowExpenses (expensesJSON, finishedField, loadId, userId = 0, trucks_list = {}) {
      const truckKeys = Object.keys(trucks_list);
      this.selectedExpensesLoadId = loadId;
      this.selectedExpensesUserId = userId;
      this.selectedExpensesTruckId = 0;
      if (truckKeys.length > 0) {
        this.selectedExpensesTruckId = trucks_list[truckKeys[0]].truckId;
      }
      this.selectedExpenses = JSON.parse(expensesJSON) || [];
      this.selectedFinishedField = finishedField;
      this.expensesModalKey = new Date().getTime();
      this.expensesModal = true;
    },
    onShowDocuments (fields) {
      if (fields) {
        this.selectedLoadFields = fields;
        this.documentsModalKey = new Date().getTime();
        this.documentsModal = true;
      }
    },
    onShowFinancialData (loadData) {
      this.selectedFinancesLoadId = loadData.id;
      this.selectedExpenses = JSON.parse(loadData.expensesJSON);
      this.selectedLoadLocations = loadData.locationsJSON;
      this.selectedLoadExcludeDispatcherPayment = loadData.isExcludeDispatcherPayment
      this.financesModalKey = new Date().getTime();
      this.financesModal = true;
    },
    getEmptyTotals () {
      this.$store.dispatch('reports/totalsCounts', {
        shiftsCount: 0,
        totalMiles: 0,
        totalDuration: 0,
        totalDetention: 0,
        totalCompensations: 0,
        totalDeductions: 0,
        totalBonuses: 0,
        totalRate: 0,
        totalSalary: 0
      });
      return this.$store.getters['reports/totalsCounts'];
    },
    onRowClick ($event) {
      this.selectedItem = $event;
      this.shiftModal = true;
      this.shiftModalKey = new Date().getTime();
    },
    isMapsOdometerTreshholdReached (mapsMiles, odometerMiles) {
      return Math.abs(mapsMiles - odometerMiles) > this.milesDiffHighlightThreshold;
    },
    onItemSelected () {
      this.$nextTick(() => {
        let selected = [];
        for (let i = 0; i < this.selectedItems.length; i++) {
          selected.push(parseInt(this.selectedItems[i]['id']));
        }
        this.$emit('selected', selected);
      });
    },
    onFilterChanged (e) {
      let i = 0;
      let field = '';
      let value = '';
      const filtersFields = Object.keys(e);
      for (i = 0; i < filtersFields.length; i++) {
        field = filtersFields[i];
        value = Object.prototype.hasOwnProperty.call(e, field) ? e[field] : null;
        if (value !== '') {
          this[field] = Array.isArray(value) ? value.join(',') : value;
        }
      }
      if (this.isFilterInitialized) {
        this.fetchData();
      }
      this.isFilterInitialized = true;
    },
    reportTitle () {
      let result = '';
      this.$root.currentReportFileName = (this.driverName ? this.driverName : 'All Drivers') + ' ' + this.$root.t('Report') + (parseInt(this.$route.params.report_number) > 0 ? '_' + this.$route.params.report_number : '') + '_';

      if (this.shifts &&
        this.shifts.length > 1 &&
        this.shifts[0].hasOwnProperty('startedPST') &&
        this.shifts[this.shifts.length - 1].hasOwnProperty('endedPST')) {
        result += this.shifts[0].startedPST.substring(0, 10) + ' - ' + this.shifts[this.shifts.length - 1].endedPST.substring(0, 10);
        this.$root.currentReportFileName += this.$root.parseDate(this.shifts[0].startedPST.substring(0, 10)) + '_' + this.$root.parseDate(this.shifts[this.shifts.length - 1].endedPST.substring(0, 10));
      }
      if (this.shifts && this.shifts.length === 1 && this.shifts[0].hasOwnProperty('startedPST')) {
        result += this.shifts[0].startedPST.substring(0, 10);
        this.$root.currentReportFileName += this.$root.parseDate(this.shifts[0].startedPST.substring(0, 10));
      }
      return result;
    },
    fetchData (skipSelected = false, selected = []) {
      this.shiftModalKey = new Date().getTime();
      if (!this.from || !this.to) return;
      return new Promise((resolve, reject) => {
        this.loading = true;
        this.getDriverShiftsReportData(this.from, this.to, this.driverId).then((response) => {
          let _totalsCounts = this.getEmptyTotals();
          let i = 0;
          if (response.status === 'ok') {
            this.shifts = [];
            this.currentDateTime = response.currentDateTime;

            let selectedArray = [];
            if (!skipSelected && this.selectedStr) {
              selectedArray = this.selectedStr.split(',').map(v => {
                return parseInt(v);
              });
            }
            else if (!skipSelected && selected.length > 0) {
              for (let i = 0; i < selected.length; i++) {
                selectedArray.push(selected[i]);
              }
            }
            this.selectedItems = [];

            for (i = 0; i < response.shifts.length; i++) {
              const shift = response.shifts[i];
              if (selectedArray.length > 0 && selectedArray.indexOf(parseInt(shift.id)) < 0) continue;

              let trucksDistinctList = [];
              let trucksMap = {}
              for (let t = 0; t < shift.trucks.length; t++) {
                const _truck = shift.trucks[t];
                shift.distance += _truck.odometerEnd > 0 ? _truck.odometerEnd - _truck.odometerStart : _truck.driverTruckMiles - _truck.odometerStart;

                if (!trucksMap.hasOwnProperty(_truck.truckId)) {
                  trucksMap[_truck.truckId] = _truck;
                  trucksDistinctList.push(_truck)
                }
              }
              shift.trucksDistinctList = trucksDistinctList;

              // Check filters
              if (this.hasOwnProperty('filterDispatcher') && parseInt(this.filterDispatcher) > 0) {
                if (Object.keys(shift.dispatchers).length === 0) continue;
                let isExists = false;
                for (const value of Object.values(shift.dispatchers)) {
                  if (this.filterDispatcher.split(',').indexOf(value.toString()) >= 0) {
                    isExists = true;
                    break;
                  }
                }
                if (!isExists) continue;
              }
              if (this.hasOwnProperty('filterTruck') && parseInt(this.filterTruck) > 0) {
                if (Object.keys(shift.trucksDistinctList).length === 0) continue;
                let isExists = false;
                for (const [key, value] of Object.entries(shift.trucksDistinctList)) {
                  if (this.filterTruck.split(',').indexOf(key.toString()) >= 0) {
                    isExists = true;
                    break;
                  }
                }
                if (!isExists) continue;
              }
              if (this.hasOwnProperty('filterBroker') && parseInt(this.filterBroker) > 0) {
                if (Object.keys(shift.brokers).length === 0) continue;
                let isExists = false;
                for (const value of Object.values(shift.brokers)) {
                  if (this.filterBroker.split(',').indexOf(value.toString()) >= 0) {
                    isExists = true;
                    break;
                  }
                }
                if (!isExists) continue;
              }

              /*shift.isPaid = false;
              this.$root.getOverriddenFieldValue(this.driverId, this.$store.getters.overriddenFieldsTypes.SHIFTS, shift.id, 'DriversSalary').then(overriddenResponse => {
                if (overriddenResponse.status === 'ok' && overriddenResponse.result !== null) {
                  shift.isPaid = true;
                  shift.paidAmount = overriddenResponse.result.customValue;
                  shift.paidDateTime = overriddenResponse.result.customDate;
                  shift.paidNote = overriddenResponse.result.note;
                }
              });*/
              // Extract status
              /*if (shift.reportsStatuses && shift.reportsStatuses.indexOf('{') === 0) {
                const reportsStatusesObject = JSON.parse(shift.reportsStatuses);
                shift.isPaid = reportsStatusesObject.hasOwnProperty(this.driverId + '_payed');
                shift.paidAmount = reportsStatusesObject.hasOwnProperty(this.driverId + '_amount') ? reportsStatusesObject[this.driverId + '_amount'] : shift.shiftRate;
                shift.payedDateTime = reportsStatusesObject[this.driverId + '_payed'];
              }*/

              // Set amount to pay
              shift.toPayAmount = (shift.isShift ? shift.shiftRate : shift.loadsOdometerRate) + parseFloat(shift.driverDetention || 0) + parseFloat(shift.shiftToCompensate || 0) - parseFloat(shift.shiftDeductions || 0) + parseFloat(shift.shiftBonuses || 0);
              shift.currentDateTime = this.currentDateTime;

              shift.driverDetentionFormatted = this.$root.showDuration(shift.driverDetentionMinutes * 60, true);
              // Re-check is expenses pending per driver
              for (let key in shift.loads) {
                const load = shift.loads[key];
                if (load.isPendingExpenses && load.expensesJSON) {
                  const expensesArray = JSON.parse(load.expensesJSON);
                  shift.loads[key].isPendingExpenses = false;
                  for (let ei = 0; ei < expensesArray.length; ei++) {
                    if (parseInt(expensesArray[ei].driver) === parseInt(this.driverId) && expensesArray[ei].hasOwnProperty('pending') && expensesArray[ei].pending) {
                      shift.loads[key].isPendingExpenses = true;
                      break;
                    }
                  }
                }
              }

              // fields for search
              shift.origin = shift.startLocation + ' ' + shift.endLocation;
              this.shifts.push(shift);

              _totalsCounts.totalMiles += shift.isShift ? shift.shiftDistance : shift.loadsOdometerDistance;
              _totalsCounts.totalDuration += shift.shiftDuration;
              _totalsCounts.totalDetention += shift.driverDetention;
              _totalsCounts.totalCompensations += shift.shiftToCompensate;
              _totalsCounts.totalDeductions += shift.shiftDeductions;
              _totalsCounts.totalBonuses += shift.shiftBonuses;
              _totalsCounts.totalRate += shift.isShift ? parseFloat(shift.shiftRate) : parseFloat(shift.loadsOdometerRate);
            }
            _totalsCounts.shiftsCount = this.shifts.length;
            this.$store.dispatch('reports/totalsCounts', _totalsCounts);
            this.$store.commit('reports/updateReportsGroupsListKey');
          }

          this.initReportsFilters();

          this.$forceUpdate();
          this.loading = false;
          resolve({
            shiftsCount: this.totalsCounts.shiftsCount,
            totalMiles: this.totalsCounts.totalMiles,
            totalDuration: this.totalsCounts.totalDuration,
            totalDetention: this.totalsCounts.totalDetention,
            totalCompensations: this.totalsCounts.totalCompensations,
            totalDeductions: this.totalsCounts.totalDeductions,
            totalBonuses: this.totalsCounts.totalBonuses,
            totalRate: this.totalsCounts.totalRate,
            totalSalary: parseFloat(parseFloat(this.totalsCounts.totalRate) +
                         parseFloat(this.totalsCounts.totalDetention) +
                         parseFloat(this.totalsCounts.totalCompensations) +
                         parseFloat(this.totalsCounts.totalBonuses) -
                         parseFloat(this.totalsCounts.totalDeductions)).round(2),
            shifts: this.shifts
          });
        }).finally(() => {
          this.loading = false;
        });
      });
    }
  }
};
</script>
