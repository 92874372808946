export default {
  data: function () {
  },
  methods: {
    mergeBrokers (fromBrokerId, toBrokerId) {
      return this.post('/api/users/merge', { mergeFromBrokerId: fromBrokerId, mergeToBrokerId: toBrokerId });
    },
    loadUsersList (role = null, excludeRole = false, isForSelect = false, status = 1, search = '') {
      if (!role) {
        role = 'all';
      }
      return this.get('/api/users/list/' + role + '/' + excludeRole + '/' + isForSelect + '/' + status + '/' + search);
    },
    loadUsersBidding () {
      return this.get('/api/users/listForBiding');
    },
    loadUsersEmailsList () {
      return this.get('/api/users/EmailsList');
    },
    loadUserFields (id) {
      if (id) {
        return this.get('/api/users/settings/' + id);
      }
      return this.get('/api/users/settings');
    },
    loadUserFullName (id) {
      return this.get('/api/users/getUserFullName/' + id);
    },
    getUserRole (id) {
      return this.get('/api/users/getUserRole/' + id);
    },
    createUser (email, password, role, fields = {}) {
      return this.post('/api/users/add', { email: email, password: password, role: role, fields: fields });
    },
    registerUser (email, password, role, fields = {}) {
      return this.post('/api/users/register', { email: email, password: password, role: role, fields: fields });
    },
    addEmailToBroker (userId, email) {
      this.loadUserFields(userId).then((response) => {
        let emailsArray = [];
        if (response.status === 'ok') {
          emailsArray = response.fields['email'].split(',');
          if (emailsArray.indexOf(email) < 0) {
            emailsArray.push(email);
            this.updateUserFields({ email: emailsArray.join(',') }, userId);
          }
        }
      });
    },
    loadSettingsFields () {
      return this.get('/api/users/settings');
    },
    updateUserFields (fields, id) {
      if (id) {
        return this.post('/api/users/update/' + id, fields);
      } else {
        return this.post('/api/users/update', fields);
      }
    },
    updateUserPassword (password, id) {
      if (id) {
        return this.post('/api/users/updatePassword/' + id, { password: password });
      } else {
        return this.post('/api/users/updatePassword', { password: password });
      }
    },
    userSetStatusIsActive (status, id) {
      return this.post('/api/users/update/' + id, { statusIsActive: status ? 1 : 0 });
    },
    checkEmailAvailability (email, companyId = 0) {
      return this.post('/api/users/checkEmail', { email: email, companyId: companyId });
    },
    checkEmailsAvailability (emails) {
      return new Promise(async (resolve, reject) => {
        for (let i = 0; i < emails.length; i++) {
          const email = emails[i];
          const response = await this.post('/api/users/checkEmail', { email: email });

          if (response.result !== 'available') {
            resolve({ result: response.result });
          }
        }
        resolve({ result: 'available' });
      });
    },
    getDriverActivity (id, offset = 0) {
      return this.get('/api/users/driverActivity/' + id + '/' + offset);
    },
    getUsersEmailsList () {
      return new Promise(async (resolve, reject) => {
        let emailsItems = [];
        const usersResponse = await this.$root.loadUsersList();
        if (usersResponse.status === 'ok') {
          for (let i = 0; i < usersResponse.result.data.length; i++) {
            const user = usersResponse.result.data[i];
            if (user.role === 'Broker') {
              this.emailsItems = user.email.split(',');
              // Add broekrs manages emails
              const brokerManagers = user.brokerManagersJSON ? JSON.parse(user.brokerManagersJSON) : [];
              for (let i = 0; i < brokerManagers.length; i++) {
                if (brokerManagers[i].email) {
                  emailsItems.push(brokerManagers[i].email);
                }
              }
            } else {
              emailsItems.push(user.email);
            }
          }
        }
        resolve(emailsItems);
      });
    },
    async getRecipientsListByBroker (brokerId) {
      let emailsItems = [];
      /*const brokerFields = await this.$root.loadUserFields(brokerId);
      // Add broker emails
      emailsItems = brokerFields.fields && brokerFields.fields.email ? brokerFields.fields.email.split(',') : [];
      // Add broekrs manages emails
      const brokerManagers = brokerFields.fields.brokerManagersJSON ? JSON.parse(brokerFields.fields.brokerManagersJSON) : [];
      for (let i = 0; i < brokerManagers.length; i++) {
        if (brokerManagers[i].email && brokerManagers[i].email !== 'null') {
          emailsItems.push(brokerManagers[i].email);
        }
      }*/
      return emailsItems;
    },
    applyRatesToAllDrivers (rates, driverCurrentSoloRate, driverCurrentTeamRate) {
      return this.post('/api/users/applyRatesToAllDrivers', { rates: rates });
    },
    applyOneRateToAllDrivers (rate, soloRateValue, teamRateValue) {
      return this.post('/api/users/applyOneRateToAllDrivers', { rate: rate, soloRateValue: soloRateValue, teamRateValue: teamRateValue });
    },
    clearDriverRateAsCurrent (driverId) {
      return this.post('/api/users/clearDriverRateAsCurrent', { driverId: driverId });
    },
    setDriverRateAsCurrent (driverId, rateId, isEnabled) {
      return this.post('/api/users/setDriverRateAsCurrent', { driverId: driverId, rateId: rateId, isEnabled: isEnabled ? 1 : 0 });
    },
    setCurrentSoloRate (rate, dirverId) {
      this.$root.updateUserFields({
        driverCurrentSoloRate: parseFloat(rate)
      }, dirverId).then((response) => {
        if (response.status === 'ok') {
          this.$root.toast(this.$i18n.t('User.RateUpdated'), { color: 'success' });
        } else {
          this.$root.toast(response.msg, { color: 'error' });
        }
      });
    },
    setCurrentTeamRate (rate, dirverId) {
      this.$root.updateUserFields({
        driverCurrentTeamRate: parseFloat(rate)
      }, dirverId).then((response) => {
        if (response.status === 'ok') {
          this.$root.toast(this.$i18n.t('User.RateUpdated'), { color: 'success' });
        } else {
          this.$root.toast(response.msg, { color: 'error' });
        }
      });
    },
    updateDisabledNotificationsTypes (typesArray) {
      return this.post('/api/users/updateDisabledNotificationsTypes', { typesArray: typesArray }).then((response) => {
        if (response.status === 'ok') {
          this.$root.toast(this.$i18n.t('ChangesAreSaved'), { color: 'success' });
        } else {
          this.$root.toast(response.msg, { color: 'error' });
        }
      });
    },
    getUserListWithLinkedAccounts () {
      return this.get('/api/users/getUserListWithLinkedAccounts');
    },
    getUserListForTabs (role = '') {
      if (role !== '') {
        return this.get('/api/users/getUserListForTabs/' + role);
      }
      return this.get('/api/users/getUserListForTabs');
    },
    setAwayStatus (isActive) {
      return this.post('/api/users/updateAwayStatus', { isActive: isActive }).then((response) => {
        if (response.status === 'ok') {
          this.$root.toast(this.$i18n.t('ChangesAreSaved'), { color: 'success' });
        } else {
          this.$root.toast(response.msg, { color: 'error' });
        }
      });
    },
    getLinkedEmailsByUser (id = 0) {
      if (id > 0) {
        return this.get('/api/users/getLinkedEmailsByUser/' + id);
      }
      return this.get('/api/users/getLinkedEmailsByUser');
    },
    getLinkedSMTPEmailsByUser () {
      return this.get('/api/users/getLinkedSMTPEmailsByUser');
    },
    getDriverRatesList (id = 0) {
      return this.get('/api/users/getDriverRates/' + id);
    },
    addDriverRate (driverId, fields) {
      return this.post('/api/users/addDriverRates/' + driverId, {
        fields: fields
      });
    },
    updateDriverRate (id, driverId, fields) {
      return this.post('/api/users/updateDriverRates/' + id + '/' + driverId, {
        fields: fields
      });
    },
    deleteDriverRate (id) {
      return this.post('/api/users/deleteDriverRates/' + id, {});
    },
    setDriverDefaultsRates (userId) {
      return this.post('/api/users/setDriverDefaultsRates/' + userId, {});
    }
  }
};
