<template>
  <div id="driverActivityChartId">
    <v-overlay :value="loading" :absolute="true">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-layout class="pb-1 pt-1">
      <v-flex xs4 md6 class="text-right">
        <v-btn small @click="prevDay"><v-icon small>mdi-chevron-left</v-icon></v-btn>
      </v-flex>
      <v-flex xs4 md2 class="text-center pt-1">
        {{curDate}}
      </v-flex>
      <v-flex xs4 md6 class="text-left">
        <v-btn small @click="nextDay" :disabled="offset === 0"><v-icon small>mdi-chevron-right</v-icon></v-btn>
      </v-flex>
    </v-layout>

    <v-layout align-center justify-center>
      <v-flex class="offset-xl-1" style="max-width:1250px; overflow-x: auto;">
        <div :style="chartBgStyle">
          <DriverHOSChart :dataArray="series" :labels="labelsData" :height="height" :key="hosChartUpdateKey" title=""/>
        </div>
      </v-flex>
      <v-flex class="pa-2 text-left" style="min-height: 220px;">
        <v-chip color="#666666" class="white--text mt-5">
          {{totals[3]}}
          <v-avatar right class="white">
            <span class="black--text">Off</span>
          </v-avatar>
        </v-chip>
        <br/>
        <v-chip color="#ba68c8" class="white--text mt-4">
          {{totals[2]}}
          <v-avatar right class="white">
            <span class="black--text">SB</span>
          </v-avatar>
        </v-chip>
        <br/>
        <v-chip color="#4caf50" class="white--text mt-4">
          {{totals[1]}}
          <v-avatar right class="white">
            <span class="black--text">D</span>
          </v-avatar>
        </v-chip>
        <br/>
        <v-chip color="#2196f3" class="white--text mt-4">
          {{totals[0]}}
          <v-avatar right class="white">
            <span class="black--text">On</span>
          </v-avatar>
        </v-chip>
      </v-flex>
    </v-layout>
    <br/>
  </div>
</template>

<script>
import DriverHOSChart from './DriverHOSChart';
import chartsMixin from '../mixins/chartsMixin';

export default {
  name: 'DriverActivityChart',
  props: ['driverId', 'height'],
  components: { DriverHOSChart },
  mixins: [chartsMixin],
  data: function () {
    return {
      loading: true,
      polling: null,
      offset: 0,
      curDate: '',
      totals: [0, 0, 0, 0],
      hosChartUpdateKey: 'hosChartUpdateKey',
      labelsData: [],
      series: [{ data: [] }, { data: [] }, { data: [] }, { data: [] }, { data: [] }],
      chartBgStyle: {
        minWidth: '1238px',
        maxWidth: '1238px',
        minHeight: '220px',
        maxHeight: '220px',
        backgroundPosition: '38px 20px',
        backgroundSize: '1200px 200px',
        backgroundRepeat: 'no-repeat',
        backgroundImage: 'url(' + process.env.BASE_URL + 'img/driver_chart_bg.png)'
      }
    };
  },
  mounted () {
    if (this.offsetProp) {
      this.offset = this.offsetProp;
    }
    this.fetchData();
    this.polling = setInterval(() => {
      // Do not call interval methods if page not visible
      if (!this.$store.getters.isPageVisible) return;
      this.fetchData();
    }, 5 * 60 * 1000);
  },
  beforeDestroy () {
    clearInterval(this.polling);
  },
  methods: {
    nextDay () {
      if (this.offset > 0) {
        this.offset--;
        this.fetchData();
      }
    },
    prevDay () {
      this.offset++;
      this.fetchData();
    },
    fetchData () {
      if (this.driverId) {
        this.loading = true;
        this.$root.getDriverActivity(this.driverId, this.offset).then((response) => {
          if (response.status === 'ok') {
            /*
                            tip
                            if (keeptruckindata.duty_status === "sleeper") statusID = 3;
                            if (keeptruckindata.duty_status === "off_duty") statusID = 4;
                            if (keeptruckindata.duty_status === "on_duty") statusID = 1;
                            if (keeptruckindata.duty_status === "driving") statusID = 2;
                            * */

            this.curDate = response.date;

            const onDutyTime = this.$root.secondsToHms(response.result.series.filter((item) => { return item === 1; }).length * 5 * 60);
            this.$set(this.totals, 0, String(onDutyTime.hours).padStart(2, '0') + ':' + String(onDutyTime.minutes).padStart(2, '0'));

            const sleeperTime = this.$root.secondsToHms(response.result.series.filter((item) => { return item === 3; }).length * 5 * 60);
            this.$set(this.totals, 2, String(sleeperTime.hours).padStart(2, '0') + ':' + new String(sleeperTime.minutes).padStart(2, '0'));

            const drivingTime = this.$root.secondsToHms(response.result.series.filter((item) => { return item === 2; }).length * 5 * 60);
            this.$set(this.totals, 1, String(drivingTime.hours).padStart(2, '0') + ':' + String(drivingTime.minutes).padStart(2, '0'));

            const offDutyTime = this.$root.secondsToHms(response.result.series.filter((item) => { return item === 4; }).length * 5 * 60);
            this.$set(this.totals, 3, String(offDutyTime.hours).padStart(2, '0') + ':' + String(offDutyTime.minutes).padStart(2, '0'));

            this.labelsData = response.result.labels;
            this.series[0].data = response.result.series.map((item) => { return item === 1 ? 1 : null; });
            this.series[1].data = response.result.series.map((item) => { return item === 2 ? 2 : null; });
            this.series[2].data = response.result.series.map((item) => { return item === 3 ? 3 : null; });
            this.series[3].data = response.result.series.map((item) => { return item === 4 ? 4 : null; });
            this.series[4].data = response.result.series

            this.hosChartUpdateKey = 'hosChartUpdateKey' + new Date().getTime()

            this.loading = false;
          }
        });
      }
    }
  }
};
</script>
