<template>
  <div class="pa-1">
    <v-overlay :value="(loading || !fields) && !error" :absolute="true">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <div v-if="error">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{error}}</h1>
      </v-flex>
    </div>
    <div v-if="fields">
      <v-layout wrap>
        <v-flex xs12>
          <v-list>
            <v-layout wrap>
              <v-flex xs12 md4>
                <v-list>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-avatar size="80" :color="(isFullName && !fields.avatarUPLOAD) ? '#015aaa' : null">
                        <v-img :src="$root.getImageUrl('avatar', fields.avatarUPLOAD)" v-if="fields.avatarUPLOAD"/>
                        <span class="white--text" v-else-if="isFullName">{{avatarLetters}}</span>
                        <v-icon large v-else>account_circle</v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{fields.firstName}} {{fields.lastName}}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="fields.role">
                        {{$t('Roles.' + fields.role)}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
              <v-flex xs12 md4>
                <template v-if="fields.role === $store.getters.roleDriver && isDriverRatesVisible">
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle v-if="fields.driverCurrentSoloRate > 0">
                          <b>{{$t('User.Fields.driverCurrentSoloRate')}}</b>: ${{fields.driverCurrentSoloRate}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-else>
                          <b>{{$t('User.Fields.driverCurrentSoloRate')}}</b>: ${{defaultDriverRate.soloRate}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="fields.driverCurrentTeamRate > 0">
                          <b>{{$t('User.Fields.driverCurrentTeamRate')}}</b>: ${{fields.driverCurrentTeamRate}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-else>
                          <b>{{$t('User.Fields.driverCurrentTeamRate')}}</b>: ${{defaultDriverRate.teamRate}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="fields.driverCurrentRateExpiredDATE">
                          <b>{{$t('User.Fields.driverCurrentRateExpiredDATE')}}</b>: {{$root.formatDate(fields.driverCurrentRateExpiredDATE)}}
                          <template v-if="expirationDaysLeft >= 0">
                            ({{$tc('User.DaysLeft', expirationDaysLeft)}})
                          </template>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </template>
              </v-flex>
              <v-flex xs12 md4>
                <div v-if="$root.isOnline && $root.isUsersListEditAllowed(role)" :class="$root.isMobile ? 'text-center' : 'mt-5 mr-2 text-right'">
                  <v-btn text small class="ma-1" @click="$root.setViewAs(fields.id, fields.firstName + ' ' + fields.lastName)" v-if="!$cookies.get('ogt') && $store.getters.isUserRoleSuperAdmin && fields.role !== 'Broker' && fields.id !== $store.getters.currentUser.id">
                    <v-icon :left="!$root.isMobile">fa-user-secret</v-icon>
                    {{$vuetify.breakpoint.xs ? '' : $t('ViewAs')}}
                  </v-btn>
                  <v-btn text small class="ma-1" @click="$root.onShowUserEditPreview(fields.id, fields.firstName +' '+fields.lastName)" v-if="$root.isUsersListEditAllowed(role)">
                    <v-icon :left="!$root.isMobile">fa-user-edit</v-icon>
                    {{$vuetify.breakpoint.xs ? '' : $t('Edit')}}
                  </v-btn>
                  <v-menu offset-y v-if="fields.role !== 'SuperAdministrator' && $root.isUsersListEditAllowed(role)">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        :class="statusBtnClass"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span v-if="fields.statusIsActive === 1" color="success">
                          Active
                        </span>
                        <span v-if="fields.statusIsActive === 0" class="text-error">
                          Inactive
                        </span>
                        <span v-if="fields.statusIsActive === -1" color="info">
                          Pending
                        </span>
                        <v-icon right>mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="activeToggle(fullName, fields.statusIsActive === 1, fields.id)">
                        {{ fields.statusIsActive === 1 ? $root.isMobile ? '' : $t('Deactivate') : $vuetify.breakpoint.xs?'':$t('Activate')}}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-flex>
            </v-layout>
          </v-list>
        </v-flex>
      </v-layout>

      <v-tabs v-model="tab" align-with-title center-active show-arrows active-class="tabs-active-color">
        <v-tab key="info">
          <v-icon left>mdi-information</v-icon>{{$t('User.UserInformation')}}
        </v-tab>
        <v-tab key="documents">
          <v-icon left>mdi-file-multiple</v-icon>{{$t('User.UserDocuments')}}
        </v-tab>
        <v-tab key="driverRates" v-if="role === 'Driver' && isDriverRatesVisible">
          <v-icon left>mdi-currency-usd</v-icon>{{$t('User.Fields.driverRates')}}
        </v-tab>
        <v-tab key="loads"
               v-if="$root.isReportAllowedToViewByUser(userId) && (role === $store.getters.roleDispatcher || role === $store.getters.roleDriver || role === $store.getters.roleDriverOwnerOperator)">
          <v-icon left>mdi-dolly</v-icon>{{ $t('User.UserLoads') }}
        </v-tab>
        <v-tab key="reports"
               v-if="$root.isReportAllowedToViewByUser(userId) && (role === $store.getters.roleDispatcher || role === $store.getters.roleDriver || role === $store.getters.roleDriverOwnerOperator)">
          <v-icon left>mdi-file-chart</v-icon>{{ $t('User.UserReports') }}
        </v-tab>
        <v-tab key="logs" v-if="$root.isAccessRightAllowView($store.getters.menuRights['Logs'])">
          <v-icon left>mdi-script-text</v-icon>{{$t('User.UserLogs')}}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="info">
          <v-row>
            <v-flex xs12 class="ma-6">
              <v-card>
                <DriverHOSCard :userId="userId" v-if="userId && role === 'Driver'" :recap="false" :showActivityChart="true"/>
              </v-card>
            </v-flex>
          </v-row>

          <v-row class="ma-2">
            <v-flex xs12 md6>
              <UserInformation :fields="fields"
                               :user-role="role"
                               :is-own-settings="isOwnSettings"
                               :hideTitle="true" />
            </v-flex>
            <v-flex xs12 md6 class="text-center">
              <DriverHOSCard :userId="userId" v-if="userId && 'Driver' === role" :recap="false" :showHOSData="true"/>
              <!-- <v-card flat v-if="!isFieldHidden('signature')">
                <v-card-title>Signature</v-card-title>
                <canvas id="signaturePad" style="border: 2px dashed #ccc; border-radius: 10px;" width="300px" height="200px"/>
              </v-card> -->
              <!--<TruckCard ref="truckCard" :key="truckData.updateKey" v-if="truckData" :truckData="truckData" :loadsData="truckData.loadsData"></TruckCard>-->
              <!--<v-card class="mx-auto" v-if="role === 'Driver' && !truckData">
                <v-card-title>{{$t('Trucks.NoTruck')}}</v-card-title>
              </v-card>-->
            </v-flex>
          </v-row>

          <v-row class="ma-2 text-center" v-if = "role === 'Broker' && getBrokerManagers.length">
            <v-card width="100%">
              <v-card-title>
                <h4>{{$t('User.Fields.brokerManagersJSON')}}</h4>
              </v-card-title>
              <v-card-text>

                <v-data-table style="width:100%;text-align: left;"
                              dense
                              :headers="brokerManagersHeaders"
                              :items="getBrokerManagers"
                              item-key="uid"
                              :disable-sort="true">
                  <template v-slot:item.phone="{ item }">
                    <v-select v-if="item.phonesArray.length"
                              :items="item.phonesArray"
                              v-model="item.selectedPhone"
                              item-text="phone"
                              item-value="phone"
                              style="max-width: 300px"
                              hide-details
                              dense
                              outlined>
                      <template v-slot:selection="data">
                        <v-icon small>{{data.item.icon}}</v-icon> &nbsp;{{data.item.phone}}&nbsp;<span v-if="data.item.ext">Ext. {{data.item.ext}}</span>
                      </template>
                      <template v-slot:item="data">
                        <v-icon small>{{data.item.icon}}</v-icon> &nbsp;{{data.item.phone}}&nbsp;<span v-if="data.item.ext">Ext. {{data.item.ext}}</span>
                      </template>
                    </v-select>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn :href="'mailto:' + item.email" small icon><v-icon small>fa-paper-plane</v-icon></v-btn>
                    <v-btn v-if="item.selectedPhone" :href="'tel:' + item.selectedPhone" small icon color="success"><v-icon small>fa-phone</v-icon></v-btn>
                  </template>
                </v-data-table>

              </v-card-text>
            </v-card>
          </v-row>
        </v-tab-item>

        <v-tab-item key="documents">
          <UserDocuments :user-id="userId" :user-role="fields.role" :fields="fields" :key="fieldsKey"/>
        </v-tab-item>

        <v-tab-item key="driverRates"  v-if="role === 'Driver' && isDriverRatesVisible">
          <DriverRates
            :readonly="!isDriverRatesEditable"
            :currentSoloRate="fields.driverCurrentSoloRate"
            :currentTeamRate="fields.driverCurrentTeamRate"
            :userId="userId"
            @fetch="fetchData"
          />
        </v-tab-item>

        <v-tab-item key="loads" v-if="$root.isReportAllowedToViewByUser(userId) && (role === $store.getters.roleDispatcher || role === $store.getters.roleDriver || role === $store.getters.roleDriverOwnerOperator)">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col xs-12 md-4 v-if="!$root.isMobile">
                </v-col>
                <v-col xs-12 md-4 class="text-center">
                  <template v-if="mode !== 'all'">
                    <v-btn text class="mr-1" @click="prevInterval">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <date-range-picker :key="dateRangePickerKey"
                                       v-model="dateRange"
                                       :options="dateRangeOptions"
                                       format="MM/DD/YYYY"
                                       :style="$vuetify.theme.dark ? {color:'#fff'} : null"
                                       @input="dateRangeChange"/>
                    <v-btn text class="ml-1" :disabled="offset <= 0" @click="nextInterval">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </template>
                </v-col>
                <v-col xs-12 md-4 :class="$root.isMobile ? 'text-center' : 'text-right'">
                  <v-btn-toggle v-model="mode" style="margin-top:-10px;" tile dense group mandatory @change="onTimeFrameChanged">
                    <v-btn value="day">1d</v-btn>
                    <v-btn value="week">1w</v-btn>
                    <v-btn value="month">1m</v-btn>
                    <v-btn value="year">1y</v-btn>
                    <v-btn value="all">all</v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <DriverShiftsReport v-if="role === $store.getters.roleDriver"
                                  type="drivers"
                                  :key="reportKey"
                                  :from="$root.parseDate(dateRange[0])"
                                  :to="$root.parseDate(dateRange[1])"
                                  :driverId="userId"
                                  :driverName="fields.firstName + ' ' + fields.lastName"
                                  :hideActionsBar="true"
                                  :hideTitle="true"
              />
              <DispatcherReport v-if="role === $store.getters.roleDispatcher"
                                :key="reportKey"
                                type="dispatchers"
                                :from="$root.parseDate(dateRange[0])"
                                :to="$root.parseDate(dateRange[1])"
                                :dispatcherId="userId"
                                :hideActionsBar="true"
                                :hideTitle="true"
                                />
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item key="reports" v-if="$root.isReportAllowedToViewByUser(userId) && (role === $store.getters.roleDispatcher || role === $store.getters.roleDriver || role === $store.getters.roleDriverOwnerOperator)">
          <ReportsGroupsList v-if="role === 'Driver'"
                             type="drivers"
                             :userId="userId"
                             :totalsCountsNames="$store.getters.driversReportsTotalsCountsNames"
                             :key="reportKey"/>

          <ReportsGroupsList v-if="role === 'Dispatcher'"
                             type="dispatcher"
                             :userId="userId"
                             :totalsCountsNames="$store.getters.driversReportsTotalsCountsNames"
                             :key="reportKey"/>
        </v-tab-item>

        <v-tab-item key="logs"
                    v-if="$root.isAccessRightAllowView($store.getters.menuRights['Logs'])">
          <UserLogs :user="userId" :title="true"/>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import ApexCharts from 'apexcharts/dist/apexcharts.js';
import UserLogs from './../../components/UserLogs.vue';
import userFunctionsMixin from './../../mixins/userFunctionsMixin';
import fieldAccessRightMixin from '../../mixins/fieldAccessRightsMixin';
import userFormsCommonMixin from '../../mixins/userFormsCommonMixin';
import DriverHOSCard from './../../components/DriverHOSCard.vue';
import DriverShiftsReport from './../../views/Reports/DriverShiftsReport.vue';
import DispatcherReport from './../../views/Reports/DispatcherReport.vue';
import DriverRates from './../../components/DriverRates.vue';
import UserDocuments from './UserDocuments';
import ReportsGroupsList from '../../views/Reports/ReportsGroupsList';
import UserInformation from './UserInformation';

export default {
  props: ['id'],
  components: { UserInformation, UserDocuments, UserLogs, DriverHOSCard, DriverShiftsReport, DispatcherReport, DriverRates, ReportsGroupsList },
  mixins: [userFunctionsMixin, fieldAccessRightMixin, userFormsCommonMixin],
  data: () => ({
    pooling: null,
    documentNumber: 0,
    brokerManagersHeaders: [
      { text: 'Name', value: 'name', divider: false },
      { text: 'Phone', value: 'phone', divider: false },
      { text: 'Email', value: 'email', divider: false },
      { text: '', value: 'actions', divider: false }
    ],
    loading: true,
    error: '',
    role: '',
    tab: null,
    panels: [0],
    ownerOperator: false,
    fields: null,
    rights: null,
    fieldsKey: new Date().getTime(),
    truckData: null,
    selectedTruck: 0,
    lastTruckLat: 0,
    lastTruckLng: 0,
    dateRange: [],
    offset: 0,
    mode: 'day',
    timeframe: 'day',
    dateRangePickerKey: new Date().getTime(),
    dateRangeOptions: {},
    reportKey: new Date().getTime(),
    defaultDriverRate: {},
    expirationDaysLeft: 0,
    isDriverRatesVisible: false,
    isDriverRatesEditable: false
  }),
  computed: {
    userId () {
      return this.id || this.$route.params.id;
    },
    statusBtnClass () {
      if (this.fields.statusIsActive === 0) {
        return 'error';
      } else if (this.fields.statusIsActive === 1) {
        return 'success';
      } else if (this.fields.statusIsActive === -1) {
        return 'info';
      }
      return '';
    },
    substractIntervalCode () {
      if (this.mode === 'day') {
        return 'd';
      } else if (this.mode === 'week') {
        return 'w';
      } else if (this.mode === 'month') {
        return 'M';
      } else if (this.mode === 'year') {
        return 'Y';
      }
      return 'd';
    },
    isFullName () {
      return this.fields && this.fields.hasOwnProperty('firstName') && this.fields.hasOwnProperty('lastName');
    },
    fullName () {
      return this.fields.firstName + ' ' + this.fields.lastName;
    },
    isOwnSettings () {
      return !this.$route.params.id;
    },
    avatarLetters () {
      return (this.fields.firstName && this.fields.lastName) ? this.fields.firstName.charAt(0) + this.fields.lastName.charAt(0) : '';
    },
    getBrokerManagers () {
      this.fields.brokerManagersJSON = this.fields.brokerManagersJSON ? JSON.parse(this.fields.brokerManagersJSON) : [];

      let brokerManagers = [];
      for (let k = 0; k < this.fields.brokerManagersJSON.length; k++) {
        let manager = this.fields.brokerManagersJSON[k];
        manager['selectedPhone'] = manager.phone;
        manager['phonesArray'] = [];
        if (manager.phone) {
          manager['phonesArray'].push({ phone: manager.phone, ext: manager.phoneExt, icon: 'fa-phone' });
        }
        if (manager.phoneCell) {
          manager['phonesArray'].push({ phone: manager.phoneCell, ext: manager.phoneCellExt, icon: 'fa-mobile-alt' });
        }
        if (manager.phoneHome) {
          manager['phonesArray'].push({ phone: manager.phoneHome, ext: manager.phoneHomeExt, icon: 'fa-home' });
        }
        if (manager.phoneFax) {
          manager['phonesArray'].push({ phone: manager.phoneFax, ext: manager.phoneFaxExt, icon: 'fa-fax' });
        }
        if (manager.phoneAdditional) {
          manager['phonesArray'].push({ phone: manager.phoneAdditional, ext: manager.phoneAdditionalExt, icon: 'fa-phone' });
        }

        brokerManagers.push(manager);
      }
      return brokerManagers;
    }
  },
  watch: {
    panels: function () {
      this.$root.setLocalStorageItem('user_panels_' + this.userId, JSON.stringify(this.panels));
    },
    tab (val) {
      this.$root.setLocalStorageItem('UsersPageTab', val);
    }
  },
  mounted () {
    this.updatingEnabled = true;
    if (this.id) {
      this.userId = this.id;
    }
    this.$nextTick(() => {
      if (this.$root.getLocalStorageItem('userReportsTimeFrame')) {
        this.timeframe = this.$root.getLocalStorageItem('userReportsTimeFrame');
        this.mode = this.timeframe;
      }

      this.tab = parseInt(this.$root.getLocalStorageItem('UsersPageTab'));

      if (this.$root.getLocalStorageItem('user_panels_' + this.userId)) {
        let _val = JSON.parse(this.$root.getLocalStorageItem('user_panels_' + this.userId));
        if (_val) {
          this.panels = _val;
        }
      }

      this.$root.setLocalStorageItem('user_panels_', JSON.stringify(this.userId));

      if (!this.dateRange[0] || !this.dateRange[1]) {
        this.updateDatesRange();
      }

      this.fetchData(false);

      this.polling = setInterval(() => {
        // Do not call interval methods if page not visible
        if (!this.$store.getters.isPageVisible) return;
        this.fetchData(false);
        if (this.role === 'Driver') {
          if (this.truckData && this.truckData.coordsUpdated) {
            if (this.$refs.truckCard) {
              this.$refs.truckCard.updateMarker();
            }
            this.$forceUpdate();
          }
        }
      }, this.$store.getters.pullInterval);

      if (this.$refs.chart) {
        // HTML element exists
        this.options['theme'] = {
          mode: this.$vuetify.theme.dark ? 'dark' : 'light'
        };
        var chart = new ApexCharts(this.$refs.chart, this.options);
        chart.render();
      }
    });
  },
  beforeDestroy () {
    clearInterval(this.polling);
  },
  methods: {
    loadTruck (id) {
      // Load selected truck data
      this.$root.getTruck(id).then(async (response) => {
        if (response.status === 'ok') {
          this.truckData = response.result;
          this.truckData.keeptruckindata = JSON.parse(this.truckData.keeptruckindata);

          this.truckData.loadsData = [];
          for (let j = 0; j < this.truckData.loads.length; j++) {
            this.truckData.loadsData.push({
              id: this.truckData.loads[j]['id'],
              uid: this.truckData.loads[j]['uid'],
              title: this.truckData.loads[j]['title'],
              description: this.truckData.loads[j]['description'],
              locationsJSON: this.truckData.loads[j]['locationsJSON']
            });

            if (this.truckData.loads[j]['locationsJSON'] &&
                this.truckData.loads[j]['locationsJSON'] !== '' &&
                this.truckData.loads[j]['locationsJSON'] !== 'null') {
              const _locations = JSON.parse(this.truckData.loads[j]['locationsJSON'].replace(/\n/g, '\\n').replace(/\r/g, '\\r').replace(/\t/g, '\\t'));
              this.truckData.loadsData[this.truckData.loadsData.length - 1]['status'] = this.$root.getLoadStatus(_locations);
            }
          }
          this.truckData['notifications'] = await this.$root.getTuckNotifications(this.truckData, this.$store.getters['globalSettingsFields']);
          this.truckData.coordsUpdated = false;
          if (this.lastTruckLat !== this.truckData.lat ||
              this.lastTruckLng !== this.truckData.lng) {
            this.truckData.coordsUpdated = true;
            this.truckData.updateKey = new Date().getTime();
          }
        } else {
          // TODO show error on truck card
        }
      });
    },
    clear () {
      this.fetchData();
    },
    remountReports () {
      this.reportKey = new Date().getTime();
    },
    onTimeFrameChanged () {
      this.timeframe = this.mode;
      this.offset = 0;
      this.updateDatesRange();
      this.$forceUpdate();
      this.$root.setLocalStorageItem('userReportsTimeFrame', this.timeframe);
    },
    updateDatesRange () {
      if (this.mode === 'all') {
        this.dateRange[0] = '01/01/1900';
        this.dateRange[1] = '01/01/3000';
      } else {
        this.dateRange[0] = this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).subtract(this.offset, this.substractIntervalCode).startOf(this.mode).format('MM/DD/YYYY');
        this.dateRange[1] = this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).subtract(this.offset, this.substractIntervalCode).endOf(this.mode).format('MM/DD/YYYY');
      }
      this.dateRangeOptions.startDate = this.dateRange[0];
      this.dateRangeOptions.endDate = this.dateRange[1];
      this.dateRangePickerKey = new Date().getTime();
    },
    prevInterval () {
      this.offset++;
      this.updateDatesRange();
      this.remountReports();
      this.$forceUpdate();
    },
    nextInterval () {
      if (this.offset === 0) return;
      this.offset--;
      this.updateDatesRange();
      this.remountReports();
      this.$forceUpdate();
    },
    dateRangeChange () {
      this.remountReports();
      this.$forceUpdate();
    },
    fetchData () {
      return new Promise((resolve, reject) => {
        if (!this.$root.isUsersListAllowed(this.role)) {
          this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
          this.loading = false;
          return;
        }
        this.$root.getAccessRightsByField('users', 'driverRates').then((response) => {
          if (response.status === 'ok') {
            const rights = response.rights;
            let _r = this.$root.getAccessRightsArrayFromNumber(rights);
            if (_r[1] === '1') {
              this.isDriverRatesVisible = true;
            }
            if (_r[2] === '1') {
              this.isDriverRatesEditable = true;
            }
          }
        });

        this.$root.loadUserFields(this.userId).then((response) => {
          if (response.status === 'ok') {
            // this.setFields(response.fields, response.rights);
            this.role = response.role;

            if (this.role === this.$store.getters.roleDriver) {
              this.$root.getDriverRatesList(this.userId).then(response => {
                if (response.status === 'ok') {
                  if (response.results.length > 0) {
                    this.$set(this, 'defaultDriverRate', response.results.find(el => el.name === 'Default Miles'));
                  }
                }
              });
            }

            this.$set(this, 'fields', response.fields);
            this.$set(this, 'rights', response.rights);

            /*if (this.signaturePad !== null) {
              this.signaturePad.fromDataURL(this.fields.signature);
            }*/

            if (this.fields.driverCurrentRateExpiredDATE) {
              this.expirationDaysLeft = Math.abs(this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).diff(this.fields.driverCurrentRateExpiredDATE, 'days'));
            }

            // If role driver, load truck info
            if (this.role === 'Driver') {
              // Set selected truck
              this.selectedTruck = response.fields.driverTruck;
              this.loadTruck(response.fields.driverTruck);
            }
            this.$set(this, 'fieldsKey', new Date().getTime());

            this.applyRights(this.role, 'users', { deleteUsersDocuments: 0 });
            resolve(response);
          } else if (response.status === 'error') {
            this.error = response.msg;
          }
        }).finally(() => {
          this.loading = false;
        });
      });
    },
  }
};
</script>
