<template>
<div v-if="$root.isMainCRM">
  <v-list dense>
    <v-list-item @click="$root.route('/companies/list')"
                 v-if="$root.$store.getters.isUserRoleSuperAdmin"
                 :dark="$root.getPagePath() === '/companies/list'"
                 :style="$root.getPagePath() === '/companies/list' ? {background: $root.currentTheme.primary} : null">
      <v-list-item-icon>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              :dark="$root.getPagePath() === '/companies/list'"
            >mdi-domain</v-icon>
          </template>
          {{$t('LeftMenu.Companies')}}
        </v-tooltip>
      </v-list-item-icon>
      <v-list-item-title>{{$t('LeftMenu.Companies')}}</v-list-item-title>
    </v-list-item>
    <!-- Languages -->
    <v-list-group :value="false">
      <template v-slot:activator>
        <v-list-item-avatar :size="32"><v-icon>fa-language</v-icon></v-list-item-avatar>
        <v-list-item-title>{{$t('LeftMenu.Languages')}}</v-list-item-title>
      </template>

      <v-list-item v-for="lang in $store.getters.languages" :key="lang.locale" link @click="$root.setLanguageLocale(lang.locale)">
        <v-list-item-icon>
          <v-img style="width:32px;height:16px;" :src="'/img/flags/' + lang.flag + '.svg'"></v-img>
        </v-list-item-icon>
        <v-list-item-title v-text="lang.name"></v-list-item-title>
      </v-list-item>
    </v-list-group>

    <v-list-item @click="$root.toggleDarkTheme">
      <v-list-item-icon>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">fa-adjust</v-icon>
          </template>
          {{$t('LeftMenu.InvertColors')}}
        </v-tooltip>
      </v-list-item-icon>
      <v-list-item-title>{{$t('LeftMenu.InvertColors')}}</v-list-item-title>
    </v-list-item>

    <v-list-item @click="$root.logout">
      <v-list-item-icon>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-exit-run</v-icon>
          </template>
          {{$t('Logout')}}
        </v-tooltip>
      </v-list-item-icon>
      <v-list-item-title>{{$t('Logout')}}</v-list-item-title>
    </v-list-item>
  </v-list>
</div>
<div v-else>
  <v-list dense>
      <v-list-group :value="false">
        <template v-slot:activator>
          <v-list-item-avatar :size="32">
            <v-img :src="$root.getImageUrl('avatar', $store.getters.currentUser['avatarUPLOAD'])" v-if="$store.getters.currentUser['avatarUPLOAD']"></v-img>
            <v-icon v-else>fa-user</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{$store.getters.currentUser['firstName']}}&nbsp;{{$store.getters.currentUser['lastName']}}</v-list-item-title>
            <v-list-item-subtitle v-if="$store.getters.currentUser['role']">{{$t('Roles.' + $store.getters.currentUser['role'])}}</v-list-item-subtitle>
          </v-list-item-content>
        </template>

      <!-- Languages -->
      <v-list-group sub-group value="true">
        <template v-slot:activator>
          <v-list-item-title>{{$t('LeftMenu.Languages')}}</v-list-item-title>
          <v-list-item-icon>
            <v-icon>fa-language</v-icon>
          </v-list-item-icon>
        </template>

        <v-list-item v-for="lang in $store.getters.languages" :key="lang.locale" link @click="$root.setLanguageLocale(lang.locale)">
          <v-list-item-icon>
            <v-img style="width:32px;height:16px;" :src="'/img/flags/' + lang.flag + '.svg'"></v-img>
          </v-list-item-icon>
          <v-list-item-title v-text="lang.name"></v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-list-item @click="$root.toggleDarkTheme">
        <v-list-item-icon>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">fa-adjust</v-icon>
            </template>
            {{$t('LeftMenu.InvertColors')}}
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-title>{{$t('LeftMenu.InvertColors')}}</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="$root.isAccessRightAllowView($store.getters.menuRights['Settings'])"
                   @click="$root.route('/user/settings')"
                   :dark="$root.getPagePath() === '/user/settings'"
                   :style="$root.getPagePath() === '/user/settings' ? {background: $root.currentTheme.primary} : null">
        <v-list-item-icon>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                :dark="$root.getPagePath() === '/user/settings'"
              >fa-cog</v-icon>
            </template>
            {{$t('LeftMenu.Settings')}}
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-title>{{$t('LeftMenu.Settings')}}</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="$root.isAccessRightAllowView($store.getters.menuRights['GlobalSettings'])"
                   @click="$root.route('/settings/global')"
                   :dark="$root.getPagePath() === '/settings/global'"
                   :style="$root.getPagePath() === '/settings/global' ? {background: $root.currentTheme.primary} : null">
        <v-list-item-icon>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                :dark="$root.getPagePath() === '/settings/global'"
              >fa-cogs</v-icon>
            </template>
            {{$t('LeftMenu.GlobalSettings')}}
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-title>{{$t('LeftMenu.GlobalSettings')}}</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="$root.isAccessRightAllowView($store.getters.menuRights['AccessRights'])"
                   @click="$root.route('/settings/access_rights')"
                   :dark="$root.getPagePath() === '/settings/access_rights'"
                   :style="$root.getPagePath() === '/settings/access_rights' ? {background: $root.currentTheme.primary} : null">
        <v-list-item-icon>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                :dark="$root.getPagePath() === '/settings/access_rights'"
                >fa-user-lock</v-icon>
            </template>
            {{$t('LeftMenu.AccessRights')}}
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-title>{{$t('LeftMenu.AccessRights')}}</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="$root.isAccessRightAllowView($store.getters.menuRights['Logs'])"
                   @click="$root.route('/logs_list')"
                   :dark="$root.getPagePath() === '/logs_list'"
                   :style="$root.getPagePath() === '/logs_list' ? {background: $root.currentTheme.primary} : null">
        <v-list-item-icon>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                :dark="$root.getPagePath() === '/logs_list'"
              >fa-scroll</v-icon>
            </template>
            {{$t('LeftMenu.Logs')}}
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-title>{{$t('LeftMenu.Logs')}}</v-list-item-title>
      </v-list-item>

      <v-list-item @click="$root.logout">
        <v-list-item-icon>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-exit-run</v-icon>
            </template>
            {{$t('Logout')}}
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-title>{{$t('Logout')}}</v-list-item-title>
      </v-list-item>
    </v-list-group>

    <!--<v-list-item><v-divider></v-divider></v-list-item>-->
<!--
    <v-list-item v-if="$root.isAccessRightAllowView($store.getters.menuRights['Search'])">
      <v-list-item-icon>
        <v-icon>fa-search</v-icon>
      </v-list-item-icon>
      <v-list-item-title>
        <v-text-field
          style="max-height:48px;"
          v-model="search"
          :label="$t('Search')"
          :placeholder="$t('Search')"
          solo clearable></v-text-field>
      </v-list-item-title>
    </v-list-item>-->
  </v-list>

 <!-- <v-list dense v-if="search">
    <v-list-item
      v-for="(item,index) in searchResults"
      :key="index"
      @click="$root.route('/user/page/'+item.id)">
      <v-list-item-avatar v-if="item.avatar">
        <v-img :src="item.avatar"></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-text="item.title"></v-list-item-title>
      </v-list-item-content>

    </v-list-item>
  </v-list>-->
  <v-list dense>
    <!--<v-list-item v-if="$root.isAccessRightAllowView($store.getters.menuRights['Mails'])"
                 @click="$root.route('/mails/list')"
                 key="mails">
      <v-list-item-icon>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-badge overlap color="green">
              <template v-slot:badge>{{$store.getters.newMailsCount}}</template>
              <v-icon v-on="on" large
                      :color="parseInt($store.getters.currentUser['statusIsHaveNewEmails']) === 1 ? 'warning' : null"
                      v-html="parseInt($store.getters.currentUser['statusIsHaveNewEmails']) === 0 ? 'mdi-email-multiple' : 'mdi-email-alert'"></v-icon>
            </v-badge>
          </template>
          {{$t('LeftMenu.Mails')}}
        </v-tooltip>
      </v-list-item-icon>
      <v-list-item-title>{{$t('LeftMenu.Mails')}}</v-list-item-title>
    </v-list-item>
-->
    <v-list-item v-if="$root.isAccessRightAllowView($store.getters.menuRights['Dashboard'])"
                 @click="$root.route('/user/dashboard')"
                 key="dashboard"
                 :dark="$root.getPagePath() === '/user/dashboard'"
                 :style="$root.getPagePath() === '/user/dashboard' ? {background: $root.currentTheme.primary} : null">
      <v-list-item-icon>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              :dark="$root.getPagePath() === '/user/dashboard'"
              :size="32"
            >mdi-view-dashboard</v-icon>
          </template>
          {{$t('LeftMenu.Dashboard')}}
        </v-tooltip>
      </v-list-item-icon>
      <v-list-item-title>{{$t('LeftMenu.Dashboard')}}</v-list-item-title>
    </v-list-item>

    <v-list-item :value="false"
                  key="users"
                  class="pt-1"
                  @click="$root.route('/users')"
                  v-if="$root.isAccessRightAllowView($store.getters.menuRights['AllUsersList'])"
                  :dark="($root.getPagePath() === '/users' || $root.getPagePath().indexOf('/user/') === 0) && $root.getPagePath().indexOf('/user/dashboard') < 0 && $root.getPagePath().indexOf('/user/settings') < 0"
                  :style="($root.getPagePath() === '/users' || $root.getPagePath().indexOf('/user/') === 0) && $root.getPagePath().indexOf('/user/dashboard') < 0 && $root.getPagePath().indexOf('/user/settings') < 0 ? {background: $root.currentTheme.primary} : null">
      <!--<template v-slot:activator>-->
          <v-list-item-icon>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-badge overlap left :offset-x="36" :color="$store.getters.usersByRoles.online.all ? 'green' : 'red'">
                  <template v-slot:badge>{{$store.getters.usersByRoles.online.all}}</template>
                  <v-icon
                    v-on="on"
                    :dark="($root.getPagePath() === '/users' || $root.getPagePath().indexOf('/user/') === 0) && $root.getPagePath().indexOf('/user/dashboard') < 0 && $root.getPagePath().indexOf('/user/settings') < 0"
                    :size="24"
                  >fa-users</v-icon>
                </v-badge>
              </template>
              {{$t('LeftMenu.AllUsersList')}}
            </v-tooltip>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{$t('LeftMenu.AllUsersList')}} ({{$store.getters.usersByRoles.total.all}})
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="isUserCreateAllowed()"
                              style="margin: 0px;"
                              @click.stop="$root.route('/user/create')">
            <v-tooltip right>
              <template v-slot:activator="{ on }">

                <v-btn icon x-small v-on="on">
                  <v-icon color="green">mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{$t('LeftMenu.CreateUser')}}</span>
            </v-tooltip>
          </v-list-item-action>
    </v-list-item>
    <!--  </template>

      <template v-for="item in $store.getters.rolesList">
        <v-list-item
          v-if="$root.isAccessRightAllowView($store.getters.menuRights[item])"
          :key="item"
          @click="$root.route('/users/'+item)"
          :dark="$root.getPagePath() === '/users/'+item"
          :style="$root.getPagePath() === '/users/'+item ? {background:'#015aaa'} : null"
          class="pt-1"
        >
          <v-list-item-icon>
            <v-tooltip right>
              <template v-slot:activator="{ on }">

                <v-badge v-if="item !== 'Broker'" left bottom :offset-x="36" :offset-y="36" :color="$store.getters.usersByRoles.online[item] ? 'green' : 'red'">
                  <template v-slot:badge>{{$store.getters.usersByRoles.online[item]}}</template>
                  <v-icon v-if="item === 'Administrator'" v-on="on" :dark="$root.getPagePath() === '/users/'+item" :size="24">fa-crown</v-icon>
                  <v-icon v-if="item === 'Dispatcher'" v-on="on" :dark="$root.getPagePath() === '/users/'+item" :size="32" class="mr-0" left>mdi-face-agent</v-icon>
                  <v-icon v-if="item === 'Driver'" v-on="on" :dark="$root.getPagePath() === '/users/'+item" :size="32" class="mr-0">$vuetify.icons.drivingWheelIcon</v-icon>
                  <v-icon v-if="item === 'DriverOwnerOperator'" v-on="on"  :dark="$root.getPagePath() === '/users/'+item" :size="32" class="mr-0">mdi-car-multiple</v-icon>
                  <v-icon v-if="item === 'Engineer'" v-on="on"  :dark="$root.getPagePath() === '/users/'+item" :size="24">fa-user-cog</v-icon>
                  <v-icon v-if="item === 'AccountingManager'" v-on="on" :dark="$root.getPagePath() === '/users/'+item" :size="30" class="mr-1">fa-user-tie</v-icon>
                </v-badge>
                <v-icon v-else v-on="on" :dark="$root.getPagePath() === '/users/'+item" :size="32">mdi-account-cash</v-icon>

              </template>
              <span>{{$t('LeftMenu.'+item)}}</span>
            </v-tooltip>

          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t('LeftMenu.'+item)}} ({{$store.getters.usersByRoles.total[item]}})</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action  v-if="isUserCreateAllowed"
                               style="margin: 0px;"
                               @click.stop="$root.route('/user/create/'+item)">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn icon x-small v-on="on">
                  <v-icon small color="green">mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{$t('LeftMenu.CreateUser')}} {{$t('in')}} {{$t('LeftMenu.'+item)}}</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list-group>-->

    <!-- Shifts-->
    <v-list-group :value="false" v-if="$root.isAccessRightAllowView($store.getters.menuRights['Shifts'])  && !$store.getters.isUserRoleDriver">
      <template v-slot:activator>
          <v-list-item-icon>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">fa-route</v-icon>
              </template>
              {{$t('LeftMenu.Shifts')}}
            </v-tooltip>
          </v-list-item-icon>
          <v-list-item-title class="pl-2">
            {{$t('LeftMenu.Shifts')}}
          </v-list-item-title>
      </template>

      <v-list-item key="shiftsByDrivers"
                   @click="$root.route('/shifts/listByDrivers')"
                   v-if="$root.isAccessRightAllowView($store.getters.menuRights['Shifts']) && !$store.getters.isUserRoleDriver"
                   :dark="$root.getPagePath().indexOf('/shifts/listByDrivers') === 0"
                   :style="$root.getPagePath().indexOf('/shifts/listByDrivers') === 0 ? {background: $root.currentTheme.primary} : null">
        <v-list-item-icon>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                :dark="$root.getPagePath().indexOf('/shifts/listByDrivers') === 0"
              >mdi-map-marker-path</v-icon>
            </template>
            {{$t('LeftMenu.ShiftsByDriver')}}
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{$t('LeftMenu.ShiftsByDriver')}}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action  v-if="isShiftsCreateAllowed"
                             style="margin: 0px;"
                             @click.stop="$root.route('/shifts/create')">
          <v-btn icon x-small >
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small color="green">mdi-plus</v-icon>
              </template>
              {{$t('LeftMenu.CreateShifts')}}
            </v-tooltip>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-list-item key="shiftsByTrucks"
                   @click="$root.route('/shifts/listByTrucks')"
                   v-if="$root.isAccessRightAllowView($store.getters.menuRights['Shifts']) && !$store.getters.isUserRoleDriver"
                   :dark="$root.getPagePath().indexOf('/shifts/listByTrucks') === 0"
                   :style="$root.getPagePath().indexOf('/shifts/listByTrucks') === 0 ? {background: $root.currentTheme.primary} : null">
        <v-list-item-icon>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                :dark="$root.getPagePath().indexOf('/shifts/listByTrucks') === 0"
              >mdi-map-marker-distance</v-icon>
            </template>
            {{$t('LeftMenu.ShiftsByTrucks')}}
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{$t('LeftMenu.ShiftsByTrucks')}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>

    <v-list-item key="trucksList"
                 @click="$root.route('/trucks/list')"
                 v-if="$root.isAccessRightAllowView($store.getters.menuRights['Trucks'])"
                 :dark="$root.getPagePath() == '/trucks/list'"
                 :style="$root.getPagePath() == '/trucks/list' ? {background: $root.currentTheme.primary} : null">
      <v-list-item-icon>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              :dark="$root.getPagePath() == '/trucks/list'"
            >fa-truck-moving</v-icon>
          </template>
          {{$t('LeftMenu.Trucks')}}
        </v-tooltip>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="pl-0">
          {{$t('LeftMenu.Trucks')}}
        </v-list-item-title>
      </v-list-item-content>
        <v-list-item-action  v-if="isTrucksCreateAllowed"
                           style="margin: 0px;"
                           @click.stop="$root.route('/trucks/create')">
        <v-btn icon x-small >
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small color="green">mdi-plus</v-icon>
            </template>
            {{$t('LeftMenu.CreateTruck')}}
          </v-tooltip>
        </v-btn>
      </v-list-item-action>
    </v-list-item>

    <v-list-item key="trailersList"
                 @click="$root.route('/trucks/list/1')"
                 v-if="$root.isAccessRightAllowView($store.getters.menuRights['Trucks'])"
                 :dark="$root.getPagePath().indexOf('/trucks/list/') === 0"
                 :style="$root.getPagePath().indexOf('/trucks/list/') === 0 ? {background: $root.currentTheme.primary} : null">
      <v-list-item-icon>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              :dark="$root.getPagePath().indexOf('/trucks/list/') === 0"
            >mdi-truck-trailer</v-icon>
          </template>
          {{$t('LeftMenu.Trailers')}}
        </v-tooltip>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="pl-0">
          {{$t('LeftMenu.Trailers')}}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action  v-if="isTrucksCreateAllowed"
                           style="margin: 0px;"
                           @click.stop="$root.route('/trucks/create/1')">
        <v-btn icon x-small >
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small color="green">mdi-plus</v-icon>
            </template>
            {{$t('LeftMenu.CreateTrailer')}}
          </v-tooltip>
        </v-btn>
      </v-list-item-action>
    </v-list-item>

    <v-list-item key="bids"
                 @click="$root.route('/bids/list')"
                 v-if="$root.isAccessRightAllowView($store.getters.menuRights['Bids'])"
                 :dark="$root.getPagePath().indexOf('/bids') === 0"
                 :style="$root.getPagePath().indexOf('/bids') === 0 ? {background: $root.currentTheme.primary} : null">
      <v-list-item-icon>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              :dark="$root.getPagePath().indexOf('/bids') === 0"
              :size="30"
            >mdi-file-question</v-icon>
          </template>
          {{$t('LeftMenu.Bids')}}
        </v-tooltip>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{$t('LeftMenu.Bids')}}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action  v-if="isBidsCreateAllowed"
                           style="margin: 0px;"
                           @click.stop="$root.route('/bids/create')">
        <v-btn icon x-small >
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small color="green">mdi-plus</v-icon>
            </template>
            {{$t('LeftMenu.CreateBid')}}
          </v-tooltip>
        </v-btn>
      </v-list-item-action>

    </v-list-item>

    <v-list-item key="loads"
                 @click="$root.route('/loads/list')"
                 v-if="$root.isAccessRightAllowView($store.getters.menuRights['Loads']) && !$store.getters.isUserRoleDriver"
                 :dark="$root.getPagePath().indexOf('/loads/feed') < 0 && $root.getPagePath().indexOf('/loads/calendar') < 0 && $root.getPagePath().indexOf('/loads') === 0"
                 :style="$root.getPagePath().indexOf('/loads/feed') < 0 && $root.getPagePath().indexOf('/loads/calendar') < 0 && $root.getPagePath().indexOf('/loads') === 0 ? {background: $root.currentTheme.primary} : null">
      <v-list-item-icon>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              :dark="$root.getPagePath().indexOf('/loads/feed') < 0 && $root.getPagePath().indexOf('/loads/calendar') < 0 && $root.getPagePath().indexOf('/loads') === 0"
              :size="30"
            >mdi-dolly</v-icon>
          </template>
          {{$t('LeftMenu.Loads')}}
        </v-tooltip>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{$t('LeftMenu.Loads')}}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item key="invoices"
                 @click="$root.route('/invoices/list')"
                 v-if="$root.isAccessRightAllowView($store.getters.menuRights['Invoices'])"
                 :dark="$root.getPagePath().indexOf('/invoices') === 0"
                 :style="$root.getPagePath().indexOf('/invoices') === 0 ? {background: $root.currentTheme.primary} : null">
      <v-list-item-icon>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              :dark="$root.getPagePath().indexOf('/invoices') === 0"
              :size="26"
              class="mr-1"
              style="margin-left:5px;"
            >fa-file-invoice-dollar</v-icon>
          </template>
          {{$t('LeftMenu.Invoices')}}
        </v-tooltip>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{$t('LeftMenu.Invoices')}}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action  v-if="isInvoicesCreateAllowed"
                           style="margin: 0px;"
                           @click.stop="$root.route('/invoices/create')">
        <v-btn icon x-small>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small color="green">mdi-plus</v-icon>
            </template>
            {{$t('LeftMenu.CreateInvoice')}}
          </v-tooltip>
        </v-btn>
      </v-list-item-action>
    </v-list-item>

    <v-list-item key="charts"
                 @click="$root.route('/chart/list')"
                 v-if="isChartViewAllowed"
                 :dark="$root.getPagePath().indexOf('/chart') === 0"
                 :style="$root.getPagePath().indexOf('/chart') === 0 ? {background: $root.currentTheme.primary} : null">
      <v-list-item-icon>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              :dark="$root.getPagePath().indexOf('/chart') === 0"
              class="mr-1"
            >fa-chart-area</v-icon>
          </template>
          {{$t('LeftMenu.Charts')}}
        </v-tooltip>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{$t('LeftMenu.Charts')}}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action  v-if="isChartCreateAllowed"
                           style="margin: 0px;"
                           @click.stop="$root.route('/chart/create')">
        <v-btn icon x-small >
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small color="green">mdi-plus</v-icon>
            </template>
            {{$t('LeftMenu.CreateChart')}}
          </v-tooltip>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-list-item key="reports"
                 @click="$root.route('/reports')"
                 v-if="isReportsViewAllowed && (
                       (!$store.getters.isUserRoleDriver && !$store.getters.isUserRoleDriverOO && !$store.getters.isUserRoleDispatcher) ||
                       ($store.getters.isUserRoleDriver && isDriversReportsViewAllowed) ||
                       ($store.getters.isUserRoleDriverOO && isDriversOwnerOperatorsReportsViewAllowed) ||
                       ($store.getters.isUserRoleDispatcher && isDispatchersReportsViewAllowed)
                 )"
                 :dark="$root.getPagePath().indexOf('/reports') === 0"
                 :style="$root.getPagePath().indexOf('/reports') === 0 ? {background: $root.currentTheme.primary} : null">
      <v-list-item-icon>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              class="mr-1"
              :dark="$root.getPagePath().indexOf('/reports') === 0"
            >mdi-file-chart</v-icon>
          </template>
          {{$t('LeftMenu.Reports')}}
        </v-tooltip>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{$t('LeftMenu.Reports')}}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item key="loadsCalendar"
                 @click="$root.route('/loads/calendar')"
                 v-if="$root.isAccessRightAllowView($store.getters.menuRights['Calendar'])"
                 :dark="$root.getPagePath().indexOf('/loads/calendar') === 0"
                 :style="$root.getPagePath().indexOf('/loads/calendar') === 0 ? {background: $root.currentTheme.primary} : null">
      <v-list-item-icon>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              class="mr-1"
              :dark="$root.getPagePath().indexOf('/loads/calendar') === 0"
            >mdi-calendar-text</v-icon>
          </template>
          {{$t('LeftMenu.LoadsCalendar')}}
        </v-tooltip>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{$t('LeftMenu.LoadsCalendar')}}
        </v-list-item-title>
      </v-list-item-content>

    </v-list-item>

    <v-list-item key="loadsFeed"
                 @click="$root.route('/loads/feed')"
                 v-if="$root.isAccessRightAllowView($store.getters.menuRights['LoadsFeed'])"
                 :dark="$root.getPagePath().indexOf('/loads/feed') === 0"
                 :style="$root.getPagePath().indexOf('/loads/feed') === 0 ? {background: $root.currentTheme.primary} : null">
      <v-list-item-icon>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              class="mr-1"
              :dark="$root.getPagePath().indexOf('/loads/feed') === 0"
            >mdi-rss-box</v-icon>
          </template>
          {{$t('LeftMenu.LoadsFeed')}}
        </v-tooltip>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{$t('LeftMenu.LoadsFeed')}}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item key="documentsList"
                 @click="$root.route('/documents/list')"
                 v-if="$root.isAccessRightAllowView($store.getters.menuRights['Documents'])"
                 :dark="$root.getPagePath() == '/documents/list'"
                 :style="$root.getPagePath() == '/documents/list' ? {background: $root.currentTheme.primary} : null">
      <v-list-item-icon>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              :dark="$root.getPagePath() == '/documents/list'"
            >mdi-file-multiple</v-icon>
          </template>
          {{$t('LeftMenu.Documents')}}
        </v-tooltip>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="pl-0">
          {{$t('LeftMenu.Documents')}}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item key="registrationsList"
                 @click="$root.route('/registrations/list')"
                 v-if="$root.isAccessRightAllowView($store.getters.menuRights['Registrations'])"
                 :dark="$root.getPagePath() == '/registrations/list'"
                 :style="$root.getPagePath() == '/registrations/list' ? {background: $root.currentTheme.primary} : null">
      <v-list-item-icon>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              :dark="$root.getPagePath() == '/registrations/list'"
            >mdi-account-plus</v-icon>
          </template>
          {{$t('LeftMenu.Registrations')}}
        </v-tooltip>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="pl-0">
          {{$t('LeftMenu.Registrations')}}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!--<v-list-item>
      <v-list-item-avatar size="24">
        <a href="https://web.keeptruckin.com/#/overview" target="_blank">
          <img :src="publicPath + 'img/keeptruckin.png'"/>
        </a>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          KeepTruckin
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-avatar size="24">
        <v-btn href="https://cloud.samsara.com/o/6005213  /fleet/list" target="_blank">
          <img :src="publicPath + 'img/samsara.png'"/>
        </v-btn>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          Samsara
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>-->
  </v-list>
</div>
</template>

<script>
import fieldAccessRightMixin from './../mixins/fieldAccessRightsMixin';
import reportsMixin from './../mixins/reportsMixin';
export default {
  props: ['isMini'],
  mixins: [ fieldAccessRightMixin, reportsMixin ],
  data: () => ({
    publicPath: process.env.BASE_URL,
    admins: [
      ['Management', 'people_outline'],
      ['Settings', 'settings']
    ],
    search: null,
    searchResults: [],
    items: {
      users: {
        text: 'Users',
        icon: 'mdi-account-circle'
      }
    }
  }),
  watch: {
    search (val) {
      if (val === '') return;
      this.$root.searchByAll(val).then((response) => {
        this.searchResults = [];
        if (response.status && response.status === 'ok') {
          response.data.forEach((element) => {
            this.searchResults.push({
              id: element.id,
              title: element.firstName + ' ' + element.lastName
            });
          });
        }
      });
    }
  }
};
</script>
