<template>
    <div :id="idOverride ? idOverride : 'osm-map'" ref="osmMap" :style="{ width: '100%', height: height }"/>
</template>

<style>
  .leaflet-container {
    z-index: 1;
  }

  .leaflet-routing-container {
    display: none !important;
  }

  .leaflet-routing-alternatives-container {
    display: none !important;
 }

</style>

<script>
//import Vue from 'vue';
//import InfoWindowTruck from './InfoWindowTruck.vue';

export default {
  props: [ 'idOverride', 'waypoints', 'mapConfig', 'height', 'marker', 'markerName',
    'showTraffic', 'zoom', 'truck', 'truckId', 'trucks', 'loadId', 'loadStarted',
    'loadEnded', 'showTrucks', 'selectedTrucks', 'loadTruck', 'loadName', 'truckTelemetry', 'openedTrucksInfoProp', 'noUI', 'showCustomIcons', 'locations', 'loads', 'selectedLoads', 'dontDrawTruckPaths', 'drawPathByWaypoints' ],
  data () {
    return {
      markerPosition: null,
      openedTrucksInfosIds: [],
      map: null,
      truckIcon: {
        path: 'M12,2L4.5,20.29L5.21,21L12,18L18.79,21L19.5,20.29L12,2Z',
        fillOpacity: 1.0,
        scale: 1,
        strokeColor: '#fff',
        fillColor: '#ff5252',
        anchor: { x: 10, y: 10 },
        // eslint-disable-next-line no-undef
        //labelOrigin: new google.maps.Point(0, 0)
      },
      circleIcon: {
        // eslint-disable-next-line no-undef
        //path: google.maps.SymbolPath.CIRCLE,
        fillOpacity: 0.8,
        scale: 8.0,
        strokeWeight: 2.0,
        strokeColor: '#fff',
        fillColor: '#ff5252'
        // anchor: {x:-10,y:-10}
      }
    };
  },

  mounted () {
    this.$nextTick(() => {
      this.init();
    });
  },

  methods: {
    init () {
      let self = this;
      const container = L.DomUtil.get(this.idOverride ? this.idOverride : 'osm-map');
      if(container != null){
        container._leaflet_id = null;
      }
      this.map = window.L.map(this.idOverride ? this.idOverride : 'osm-map');//.setView([51.505, -0.09], 13);
      this.map.addControl(new L.Control.Fullscreen());

      window.L.tileLayer('https://maps.tglcrm.com/tile/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this.map);

      /*window.L.marker([34.052235, -118.243683]).addTo(map)
        .bindPopup('A pretty CSS popup.<br> Easily customizable.')
        .openPopup();*/

      /*const plan = L.Routing.Plan(this.waypoints, {
        addWaypoints: false,
        draggableWaypoints: false
      });*/

      window.L.Routing.control({
        waypoints: this.waypoints,
        draggableWaypoints: false,
        routeWhileDragging: false,
        lineOptions: {
          draggableWaypoints: false,
          addWaypoints: false
        },
        lineOptions: {
          styles: [{color: 'rgb(1, 90, 170)', opacity: 0.6, weight: 8}]
        },
        createMarker: (i, waypoint, n) => {
          const marker = L.marker(waypoint.latLng, {
            draggable: false,
            bounceOnAdd: false,
            icon: L.icon({
              iconUrl: 'https://maps.google.com/mapfiles/marker' + this.$root.alphabet[i].toUpperCase() + '.png',
              iconSize: [20, 34],
              iconAnchor: [10, 34]
            })
          });
          return marker;
        },
        router: new L.Routing.OSRMv1({
          serviceUrl: 'https://routes.tglcrm.com/route/v1'
        })
      }).on('routesfound', function(e) {
        self.$emit('route-summary', e.routes[0]);
      }).addTo(this.map);

      if (this.waypoints) {
        var markers = [];
        this.waypoints.forEach(el => {
          markers.push([el.lat, el.lng]);
        });
        this.map.fitBounds(markers);
      }

      this.processTrucks(this.map);
    },
    refreshMap() {
      if (this.map) {
        // this.streetMaps.redraw();
        this.map.invalidateSize();
      }
    },
    processTrucks(map) {
      let i = 0;
      let _trucks = [];
      if (!this.truckId && this.trucks) {
        _trucks = this.trucks;
      } else {
        _trucks.push(this.truckId);
      }

      for (let i = 0; i < _trucks.length; i++) {
        this.$root.getTruckLastPath(_trucks[i], 0, this.loadStarted, this.loadEnded).then((response) => {
          let waypoints;
          if (response.status === 'ok' && response.results && response.results.length > 0) {
            waypoints = response.results;
            if (this.loadTruck) {
              // waypoints.push({ lat: this.loadTruck.lat, lng: this.loadTruck.lng });
            }
            this.drawRouteByCoords(waypoints, map);
          }
        });
      }
    },
    drawRouteByCoords(routeWaypoints, map) {
      let i = 0;
      let _waypoints = [];
      for (i = 0; i < routeWaypoints.length; i++) {
        _waypoints.push([
          routeWaypoints[i].lat,
          routeWaypoints[i].lng
        ]);

        /*var circleMarker = L.circleMarker([
          routeWaypoints[i].lat,
          routeWaypoints[i].lng
        ], {
          color: 'red', radius: 0.1
        }).addTo(map);*/
      }
      L.polyline(_waypoints, {color: 'green', weight: 10, opacity: 0.6}).addTo(map);
    }
  }
};
</script>
