<script>
import { Line } from 'vue-chartjs';

export default {
  extends: Line,
  props: ['dataArray', 'labels', 'title'],
  data: () => ({
    dataSets: {
      labels: [],
      datasets: []
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: false,
        text: ''
      },
      tooltips: {
        // backgroundColor: '#f00',
        callbacks: {
          label: function (tooltipItem, data) {
            if (tooltipItem.datasetIndex === 4) return null;

            switch (tooltipItem.yLabel) {
            case 1:
              return 'On Duty at ' + tooltipItem.label;
            case 2:
              return 'Driving at ' + tooltipItem.label;
            case 3:
              return 'Sleep at ' + tooltipItem.label;
            case 4:
              return 'Off Duty at ' + tooltipItem.label;
            }
          }
        }
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          position: 'top',
          ticks: {
            beginAtZero: true,
            fontSize: 9,
            fontStyle: 'bold',
            maxRotation: 0,
            minRotation: 0,
            callback: function (value, index, values) {
              if (value === '12:00 AM' || value === '11:59 PM') {
                return 'M';
              } else if (value === '12:00 PM') {
                return 'N';
              /*} else if (value.indexOf(':15') > 0 || value.indexOf(':30') > 0 || value.indexOf(':45') > 0) {
                return '';*/
              } else {
                let val = value.toString();
                return val.indexOf(':00') > 0 && val.indexOf('12:') < 0 ? parseInt(val.substr(0, 2)) : null;
              }
            }
          },
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          gridLines: {
            display: false
          },
          ticks: {
            min: 0.5,
            max: 4.5,
            stepSize: 1,
            suggestedMin: 0.5,
            suggestedMax: 4.5,
            callback: function (label, index, labels) {
              switch (label) {
              case 1:
                return 'ON';
              case 2:
                return 'D';
              case 3:
                return 'SB';
              case 4:
                return 'OFF';
              }
            }
          }
        }]
      }
    }
  }),
  mounted () {
    let i = 0;
    if (this.title && this.title !== '') {
      this.options.title.display = true;
      this.options.title.text = this.title;
    }

    this.dataSets.datasets = [];
    for (i = 0; i < this.dataArray.length; i++) {
      this.dataSets.datasets.push({
        label: this.getLabel(i),
        steppedLine: true,
        pointStyle: 'rect',
        radius: i === 4 ? 0 : 3,
        borderWidth: i === 4 ? 1 : 3,
        data: this.dataArray[i].data,
        borderColor: this.getColor(i),
        spanGaps: false,
        fill: false
      });
    }

    this.dataSets.labels = this.labels;
    this.renderChart(this.dataSets, this.options);
  },
  methods: {
    getColor (id) {
      const colors = ['#2196f3', '#4caf50', '#ba68c8', '#666666', '#dddddd'];
      return colors[id];
    },
    getLabel (id) {
      let labels = ['ON', 'D', 'SB', 'OFF', ''];
      return labels[id];
    }
  }
};
</script>
