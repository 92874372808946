<template>
  <v-layout>
    <v-flex xs12>
      <v-card class="mt-2 pa-2 pt-0">
        <v-layout wrap>
          <v-flex xs12 md2 class="pt-2">
            <v-btn v-if="$root.isPendingExpensesApproveAllowed && isPendingExpenses"
                   @click="onApproveAll">
              <v-icon left color="success">mdi-check-all</v-icon>
              {{$t('Loads.ApproveAll')}}
            </v-btn>
          </v-flex>
          <v-flex xs12 md2 class="pa-1 pt-2">
            <v-select outlined
                      dense
                      hide-details
                      :label="$t('FilterType')"
                      v-model="filterType"
                      :items="[
                              {value: -1, text: $t('All')},
                              {value: 0, text: $t('Expense')},
                              {value: 1, text: $t('Compensate')},
                              {value: 2, text: $t('Bonus')},
                              {value: 3, text: $t('Deduction')}
                            ]"
            />
          </v-flex>
          <v-flex xs12 md3 class="pa-1 pt-2">
            <v-select outlined
                      dense
                      hide-details
                      :label="$t('FilterDriver')"
                      v-model="filterDriver"
                      :items="driversFilterList"
                      item-value="id"
                      item-text="fullName"
            />
          </v-flex>
          <v-flex xs12 md3 class="pa-1 pt-2">
            <v-select outlined
                      dense
                      hide-details
                      :label="$t('FilterTruck')"
                      v-model="filterTruck"
                      :items="trucksFilterList"
                      item-value="id"
                      item-text="name"
            />
          </v-flex>
          <v-flex xs12 md2 class="pa-1 text-right">
            <v-btn v-if="!readOnly" class="mb-1 mt-1 success" @click="onAddExpenseBtn()" text><v-icon left>mdi-plus</v-icon>{{$t('Loads.Expense')}}</v-btn>
          </v-flex>
        </v-layout>

        <v-list v-if="expensesFiltered.length > 0">
          <template v-for="item in expensesFiltered">
            <v-list-group
              v-if="isFiltered(item)"
              :key="item.timestamp"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-layout>
                    <v-flex xs1>
                      <v-tooltip bottom v-if="item.hasOwnProperty('dbData')" >
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">mdi-import</v-icon>
                        </template>
                        <span>{{$t('Import.ExpenseWasImported')}}</span>
                      </v-tooltip>
                      <v-icon v-if="item.pending" color="warning">mdi-timer-sand</v-icon>
                      <v-icon v-else color="success">mdi-check-bold</v-icon>
                    </v-flex>
                    <v-flex xs3 md1>
                      <v-avatar v-if="driversData[item.driver]"
                                @click.stop="$root.onShowUserPreview(item.driver, item.driverName)"
                                style="cursor: pointer;"
                                class="ml-1"
                                :size="32"
                                :color="(driversData[item.driver].driverInitials && !driversData[item.driver].driverAvatar)?'#015aaa':null">
                        <v-img :src="$root.getImageUrl('avatar', driversData[item.driver].driverAvatar)" v-if="driversData[item.driver].driverAvatar"></v-img>
                        <span style="color:#fff;" v-else-if="driversData[item.driver].driverInitials">{{driversData[item.driver].driverInitials}}</span>
                        <v-icon large v-else>account_circle</v-icon>
                      </v-avatar>
                    </v-flex>
                    <v-flex xs6 md10 class="text-left">
                      <v-list-item-title>{{item.name}}</v-list-item-title>
                      <v-list-item-subtitle>
                        <template v-if="item.timestamp > 0 && item.hasOwnProperty('truck') && item.truck > 0 && trucksData.hasOwnProperty(item.truck)">
                          {{trucksData[item.truck].name}}&nbsp;
                        </template>
                        <template v-if="item.hasOwnProperty('dbData') && item.dbData.hasOwnProperty('truckId') && item.dbData.truckId > 0 && trucksData.hasOwnProperty(item.dbData.truckId)">
                          {{trucksData[item.dbData.truckId].name}}&nbsp;
                        </template>
                        <span v-if="item.compensate === 0">{{$t('Expense')}}</span>
                        <span v-if="item.compensate === 1">{{$t('Compensate')}}</span>
                        <span v-if="item.compensate === 2">{{$t('Bonus')}}</span>
                        <span v-if="item.compensate === 3">{{$t('Deduction')}}</span>
                        <template v-if="item.hasOwnProperty('dbData')">
                          &nbsp;{{item.dbData.TrxDate}}
                        </template>
                        <template v-else-if="item.timestamp > 0">
                          &nbsp;{{$root.getDateTimeStringFromTimestamp(item.timestamp)}}
                        </template>

                      </v-list-item-subtitle>
                    </v-flex>
                    <v-flex xs md1 class="pt-3 pr-2">
                      ${{item.total}}
                    </v-flex>
                    <v-flex v-if="item.pending" shrink>
                      <v-btn v-if="$root.isPendingExpensesApproveAllowed" @click.stop="approveExpense(item.timestamp, item.name)" icon>
                        <v-icon color="success" :icon="$root.isMobile">mdi-check</v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex v-else shrink>
                      <v-btn v-if="$root.isPendingExpensesApproveAllowed" @click.stop="resetExpense(item.timestamp, item.name)" icon>
                        <v-icon color="error" :icon="$root.isMobile">mdi-cancel</v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex v-if="!readOnly" shrink>
                      <v-btn @click.stop="editExpense(item.timestamp, item.name)" icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex v-if="!readOnly" shrink>
                      <v-btn @click.stop="deleteExpense(item.timestamp, item.name)" icon>
                        <v-icon color="red">mdi-delete</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-list-item-content>
              </template>
              <v-list-item>
                <v-list-item-content>
                  <ExpenseInfo :item="item"
                               :loadId="loadId"
                               :readOnly="readOnly"
                               @edit="editExpense"
                               @approve="approveExpense"
                               @reset="resetExpense"
                               @delete="deleteExpense"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
        </v-list>
        <v-card v-else flat>
          <v-card-text class="text-center">
            <h3>No Expenses</h3>
          </v-card-text>
        </v-card>

        <v-btn  v-if="$vuetify.breakpoint.xs && expensesFiltered.length > 3 && !readOnly"
                width="100%"
                class="mb-1 mt-1 success"
                @click="addDialog = true"
                text>
          <v-icon left>mdi-plus</v-icon>{{$t('Loads.AddExpense')}}
        </v-btn>

        <v-dialog v-model="addDialog" :fullscreen="$root.isMobile" width="350px" persistent>
          <v-card>
            <v-card-title>
              <h2>{{this.editTimestamp > 0 ? $t('Loads.EditExpense') : $t('Loads.AddExpense')}}</h2><v-spacer></v-spacer><v-btn icon @click="addDialog=false;"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-layout wrap class="pa-2">
                <v-flex xs12 v-if="editTimestamp > 0">
                  <v-text-field v-model="expenseDate"
                           type="text"
                           dense
                           hide-details
                           outlined
                           :label="$t('Loads.ExpenseDate')"/>
                </v-flex>
                <v-flex xs12>
                  <SelectUser
                    v-if="!user"
                    :label="$t('Loads.SelectDriver')"
                    role="Driver"
                    :key="driversListKey"
                    :includeDriverOwnerOperator="false"
                    :value="driver"
                    :hide-prepend-icon="true"
                    @change="driverChanged($event)"
                  />
                </v-flex>
                <v-flex>
                  <SelectTruck
                    v-if="!truckId"
                    :label="$t('Loads.SelectTruck')"
                    :hide-icon="true"
                    :value="truck"
                    :showInfoButton="false"
                    :key="trucksListKey"
                    @change="truckChanged($event)"
                  />
                </v-flex>
                <v-flex xs12 class="text-center">
                  <v-select outlined
                            dense
                            hide-details
                            :label="$t('Type')"
                            v-model="expenseCompensate"
                            :items="[
                              {value: 0, text: $t('Expense')},
                              {value: 1, text: $t('Compensate')},
                              {value: 2, text: $t('Bonus')},
                              {value: 3, text: $t('Deduction')}
                            ]"/>
                </v-flex>
                <v-flex xs12>
                  <v-combobox
                    class="mt-2"
                    v-model="expensesName"
                    :rules="$root.fieldRequiredRules"
                    :items="namesItems"
                    :label="$t('Loads.ExpensesName')"
                    @change="onExpenseNameSelected"
                    :placeholder="$root.t('Loads.EnterExpensePlaceholder')"
                    outlined dense hide-details></v-combobox>
                </v-flex>
                <v-flex xs12 class="text-left" v-if="expenseCompensate === 0 || expenseCompensate === 1">
                  <v-select
                    class="mt-2 mb-2"
                    v-model="expensePaymentType"
                    :rules="$root.fieldRequiredRules"
                    :items="expenseCompensate === 1 ? expensePaymentTypes.filter(el=>el !== 'Corporate Card') : expensePaymentTypes"
                    :label="$t('PaymentType')"
                    hide-details
                    outlined dense
                  />
                  <!--<v-radio-group
                    style="margin-top:-20px;" hide-details
                    v-model="expenseCompensate"
                  >
                    <v-layout v-if="$store.getters.isUserRoleDriver && $store.getters.isUserRoleDriverOO">
                      <v-flex>
                        <v-radio :key="0" :label="$t('Expenses')" :value="0"></v-radio>
                        <v-radio :key="1" :label="$t('Compensate')" :value="1"></v-radio>
                      </v-flex>
                      <v-flex>
                        <v-radio :key="2" :label="$t('AddToSalary')" :value="2"></v-radio>
                        <v-radio :key="3" :label="$t('Fine')" :value="3"></v-radio>
                      </v-flex>
                    </v-layout>
                    <v-layout v-else>
                      <v-flex>
                        <v-radio :key="0" :label="$t('Expenses')" :value="0"></v-radio>
                      </v-flex>
                      <v-flex>
                        <v-radio :key="1" :label="$t('Compensate')" :value="1"></v-radio>
                      </v-flex>
                    </v-layout>
                  </v-radio-group>-->
                </v-flex>
                <template v-if="expenseCompensate === 0 || expenseCompensate === 1">
                  <v-flex xs12 v-show="$store.getters.role !== 'Driver' && $store.getters.role !== 'DriverOwnerOperator'">
                    <v-switch
                      v-model="isAddToRate"
                      @change="(value) => {if (!isAddToRate) { isCalcInDispatcherRate = false; }}"
                      inset dense hide-details
                      :label="$t('Loads.IsAddExpenseToRate')"></v-switch>
                  </v-flex>
                  <v-flex xs12 v-show="$store.getters.role !== 'Driver' && $store.getters.role !== 'DriverOwnerOperator'">
                    <v-switch
                      :disabled="!isAddToRate"
                      v-model="isCalcInDispatcherRate"
                      inset dense hide-details
                      :label="$t('Loads.IsCalcInDispatcherRate')"></v-switch>
                  </v-flex>
                </template>
                <v-flex xs12 v-if="expenseCompensate === 0 || expenseCompensate === 1">
                  <v-text-field class="mt-2"
                                @click="$root.selectAllInputValue"
                                v-model="expensesCount"
                                :rules="$root.fieldRequiredRules"
                                :label="isShowGallons ? $t('Loads.GallonsAmount') : $t('Loads.ExpensesAmount')"
                                @change="countChanged"
                                type="number" step="0.1" @mousewheel.prevent="" outlined dense></v-text-field>
                </v-flex>
                <v-flex xs12 v-if="expenseCompensate === 0 || expenseCompensate === 1">
                  <v-text-field class="mt-2"
                                @click="$root.selectAllInputValue"
                                v-model="expensesPerItem"
                                :rules="$root.fieldRequiredRules"
                                :label="isShowGallons ? $t('Loads.ExpensesPerGallon') : $t('Loads.ExpensesPerItem')"
                                prefix="$"
                                @change="perItemChanged"
                                type="number" step="0.1" @mousewheel.prevent="" outlined dense></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field class="mt-2"
                                @click="$root.selectAllInputValue"
                                v-model="expensesValue"
                                :rules="$root.fieldRequiredRules"
                                :label="$t('Loads.ExpensesTotal')"
                                prefix="$"
                                @change="totalChanged"
                                type="number" step="0.1" @mousewheel.prevent="" outlined dense></v-text-field>
                </v-flex>
                <v-flex xs12 v-if="expenseCompensate === 0 || expenseCompensate === 1">
                  <MultiFileUpload :field="checkUPLOAD"
                                   height="300px"
                                   @change="fileChange($event)"
                                   fieldName="publicRateConUPLOAD"
                                   uploadFolder="check"
                                   :label="$t('Loads.ExpensesAddCheck')"
                                   :deleteAllowed="true"
                                   :downloadAllowed="true"
                                   :addBtnWidth="'90%'"
                                   :readonly="readOnly"/>
                </v-flex>
                <v-flex xs12>
                  <v-btn v-if="!readOnly" width="100%" @click="saveExpense()" class="primary"><v-icon left>mdi-content-save</v-icon>&nbsp;{{$t('Save')}}</v-btn>
                  <!--<v-btn :disabled="readOnly" @click="saveEditExpense(index)" icon><v-icon color="green">mdi-content-save</v-icon></v-btn>
                  <v-btn :disabled="readOnly" @click="editTimestamp = 0;" icon><v-icon color="red">mdi-cancel</v-icon></v-btn>-->
                </v-flex>
              </v-layout>
            </v-form>
          </v-card>
        </v-dialog>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import MultiFileUpload from '../components/MultiFileUpload.vue';
import SelectUser from '../components/SelectUser.vue';
import ExpenseInfo from '../components/ExpenseInfo.vue';
import SelectTruck from '../components/SelectTruck';

export default {
  name: 'LoadExpenses',
  props: ['expensesArray', 'readOnly', 'preselectedDriver', 'user', 'isFinished', 'loadId', 'preselectedTruck', 'truckId', 'filterByDriverId', 'filterByTruckId'],
  components: { SelectTruck, MultiFileUpload, SelectUser, ExpenseInfo },
  data () {
    return {
      headers: [
        { text: this.$root.t('Loads.ExpensesAdded'), align: 'center', sortable: false, value: 'timestamp' },
        { text: this.$root.t('Loads.Driver'), align: 'center', sortable: false, value: 'driver' },
        { text: this.$root.t('Loads.ExpensesName'), align: 'center', sortable: false, value: 'name' },
        { text: this.$root.t('PaymentType'), align: 'center', sortable: false, value: 'paymentType' },
        { text: this.$root.t('CompensateType'), align: 'center', sortable: false, value: 'compensate' },
        { text: this.$root.t('Loads.ExpensesTotal'), align: 'center', sortable: false, value: 'total' },
        { text: this.isShowGallons ? this.$root.t('Loads.GallonsAmount') : this.$root.t('Loads.ExpensesAmount'), align: 'center', sortable: false, value: 'amount' },
        { text: this.isShowGallons ? this.$root.t('Loads.ExpensesPerGallon') : this.$root.t('Loads.ExpensesPerItem'), align: 'center', sortable: false, value: 'perItem' },
        { text: this.$root.t('Loads.ExpensesCheckUpload'), align: 'center', sortable: false, value: 'checkUPLOAD' },
        { text: '', align: 'center', sortable: false, value: 'additional' },
        { text: '', align: 'center', sortable: false, value: 'actions' }
      ],
      driversData: {},
      expensesKey: new Date().getTime(),
      driver: 0,
      driversListKey: 'driversListKey',
      truck: 0,
      trucksListKey: 'trucksListKey',
      addDialog: false,
      expenseDate: '',
      expensesName: '',
      expensesValue: 0,
      expensePaymentType: '',
      expenseCompensate: 0,
      expensesCount: 1,
      expensesPerItem: 0,
      expensesItems: [],
      compensationsItems: [],
      deductionsItems: [],
      bonusesItems: [],
      expensePaymentTypes: [],
      checkUPLOADLoading: false,
      checkUPLOAD: '',
      checkUPLOADUrl: '',
      editTimestamp: 0,
      isAddToRate: false,
      isCalcInDispatcherRate: false,
      isShowGallons: false,
      valid: false,
      expenseType: 0, // 0 - Expense, 10 - Deduction, 20 - Bonus
      filterType: -1,
      filterDriver: -1,
      filterTruck: -1,
      trucksFilterList: [],
      driversFilterList: [],
      trucksData: {}
    };
  },
  computed: {
    namesItems () {
      if (this.expenseCompensate === 1) {
        return this.compensationsItems;
      }
      if (this.expenseCompensate === 2) {
        return this.deductionsItems;
      }
      if (this.expenseCompensate === 3) {
        return this.bonusesItems;
      }
      return this.expensesItems;
    },
    isPendingExpenses () {
      let i = 0;
      for (i = 0; i < this.expensesArray.length; i++) {
        if (this.expensesArray[i].pending) return true;
      }
      return false;
    },
    expensesFiltered () {
      let i = 0;
      let ret = [];
      if (this.user > 0) {
        for (i = 0; i < this.expensesArray.length; i++) {
          if (parseInt(this.expensesArray[i].driver) === parseInt(this.user)) {
            ret.push(this.expensesArray[i]);
          }
        }
        return ret;
      }
      return this.expensesArray;
    }
  },
  mounted () {
    this.$root.getGlobalSettingsField('expensesNames').then((response) => {
      if (response.status === 'ok') {
        this.expensesItems = JSON.parse(response.result);
      }
    });

    this.$root.getGlobalSettingsField('toCompensateNames').then((response) => {
      if (response.status === 'ok') {
        this.compensationsItems = JSON.parse(response.result);
      }
    });

    this.$root.getGlobalSettingsField('deductionNames').then((response) => {
      if (response.status === 'ok') {
        this.deductionsItems = JSON.parse(response.result);
      }
    });

    this.$root.getGlobalSettingsField('bonusesNames').then((response) => {
      if (response.status === 'ok') {
        this.bonusesItems = JSON.parse(response.result);
      }
    });

    this.$root.getGlobalSettingsField('expensePaymentTypes').then((response) => {
      if (response.status === 'ok') {
        this.expensePaymentTypes = JSON.parse(response.result);
      }
    });

    this.$root.getTrucksList().then(response => {
      if (response.status === 'ok') {
        for (let i = 0; i < response.result.length; i++) {
          this.trucksData[response.result[i].id] = response.result[i];
        }
      }
    });

    if (parseInt(this.filterByDriverId) > 0) {
      this.filterDriver = parseInt(this.filterByDriverId);
    }

    if (parseInt(this.filterByTruckId) > 0) {
      this.filterTruck = parseInt(this.filterByTruckId);
    }

    this.updateDriversValues();
    this.fetchData();
  },
  methods: {
    isFiltered(item) {
      const isTypeFiltered   = parseInt(this.filterType)   === -1 || parseInt(this.filterType)   === parseInt(item.compensate);
      const isDriverFiltered = parseInt(this.filterDriver) === -1 || parseInt(this.filterDriver) === parseInt(item.driver) || parseInt(this.filterDriver) === parseInt(item.user);
      const isTruckFiltered  = parseInt(this.filterTruck)  === -1 || parseInt(this.filterTruck)  === parseInt(item.truck);
      const isDBTruckFiltered  = item.hasOwnProperty('dbData') && (parseInt(this.filterTruck)  === -1 || parseInt(this.filterTruck)  === parseInt(item.dbData.truckId));
      return isTypeFiltered && isDriverFiltered && (isTruckFiltered || isDBTruckFiltered);
    },
    onAddExpenseBtn () {
      this.resetModalData();
      this.editTimestamp = 0;
      this.addDialog = true;
      this.$nextTick(() => {
        if (this.preselectedDriver > 0 && !this.user) {
          this.$set(this, 'driver', this.preselectedDriver);
        } else if (this.user > 0) {
          this.$set(this, 'driver', this.user);
        }
        this.driversListKey = 'driversListKey' + new Date().getTime();

        if (parseInt(this.preselectedTruck) > 0 && !this.truckId) {
          this.$set(this, 'truck', this.preselectedTruck);
        } else if (parseInt(this.truckId) > 0) {
          this.$set(this, 'truck', this.truckId);
        }
        this.trucksListKey = 'trucksListKey' + new Date().getTime();
      });
    },
    onExpenseNameSelected (isEdit = false) {
      if (this.expensesName.toLowerCase() === 'lumper' && !isEdit) {
        this.isAddToRate = true;
        this.isCalcInDispatcherRate = false;
      } else if (this.expensesName.toLowerCase() === 'fuel' || this.expensesName.toLowerCase() === 'def') {
        this.isShowGallons = true;
      } else {
        // this.isAddToRate = false;
        // this.isCalcInDispatcherRate = false;
        this.isShowGallons = false;
      }
    },
    updateDriversValues () {
      let i = 0;
      this.driversData = {};
      for (i = 0; i < this.expensesArray.length; i++) {
        if (this.expensesArray[i].driver > 0) {
          if (this.driversData.hasOwnProperty(this.expensesArray[i].driver)) continue;
          this.$root.loadUserFields(this.expensesArray[i].driver).then((response) => {
            if (response.status === 'ok') {
              this.driversData[response.fields['id']] = {
                driverName: response.fields['firstName'] + ' ' + response.fields['lastName'],
                driverInitials: (response.fields.hasOwnProperty('firstName') ? response.fields['firstName'][0] : '') +
                                (response.fields.hasOwnProperty('lastName') ? response.fields['lastName'][0] : ''),
                driverAvatar: response.fields['avatarUpload']
              };
              this.expensesKey = new Date().getTime();
              this.$forceUpdate();
            }
          });
        }
      }
    },
    driverChanged (value) {
      this.driver = value;
    },
    truckChanged (value) {
      this.truck = value;
    },
    totalChanged (value) {
      if (this.expenseCompensate === 0 || this.expenseCompensate === 1) {
        this.expensesPerItem = new Number(this.expensesValue / this.expensesCount).toFixed(2);
      }
    },
    countChanged (value) {
      this.expensesPerItem = new Number(this.expensesValue / this.expensesCount).toFixed(2);
    },
    perItemChanged (value) {
      this.expensesValue = new Number(this.expensesPerItem * this.expensesCount).toFixed(2);
    },
    resetModalData () {
      this.expensesName = '';
      this.truck = 0;
      this.driver = 0;
      this.expensesValue = 0;
      this.expensesCount = 1;
      this.expensesPerItem = 0;
      this.checkUPLOAD = '';
      this.checkUPLOADUrl = '';
      this.isAddToRate = false;
      this.isCalcInDispatcherRate = false;
    },
    getExpenseIndexByTimestamp (timestamp, name) {
      let i = 0;
      for (i = 0; i < this.expensesArray.length; i++) {
        if (this.expensesArray[i].timestamp === timestamp) {
          return i;
        }
      }
    },
    saveExpense () {
      let index = 0;
      let expenses = this.expensesArray;
      if (this.$refs.form.validate()) {
        this.$dialog.confirm(this.$root.t('Loads.SaveExpensesConfirmation')).then((dialog) => {
          const _expensesCount = this.expensesCount && this.expensesCount > 0 ? parseInt(this.expensesCount) : 1;
          if (this.expensesValue && this.expensesValue > 0) {
            this.expensesPerItem = parseFloat(this.expensesValue) / parseFloat(_expensesCount);
          } else if (this.expensesPerItem && this.expensesPerItem > 0 && _expensesCount > 0) {
            this.expensesValue = parseFloat(this.expensesPerItem) * _expensesCount;
          }
          if (this.editTimestamp > 0) {
            index = this.getExpenseIndexByTimestamp(this.editTimestamp, this.expensesName);
            this.$set(expenses[index], 'driver', this.driver);
            this.$set(expenses[index], 'truck', this.truck);
            this.$set(expenses[index], 'name', this.expensesName);
            this.$set(expenses[index], 'paymentType', this.expensePaymentType);
            this.$set(expenses[index], 'compensate', this.expenseCompensate);
            this.$set(expenses[index], 'total', this.expensesValue);
            this.$set(expenses[index], 'amount', _expensesCount);
            this.$set(expenses[index], 'perItem', this.expensesPerItem);
            this.$set(expenses[index], 'checkUPLOAD', this.checkUPLOAD);
            this.$set(expenses[index], 'addToRate', this.isAddToRate ? 1 : 0);
            this.$set(expenses[index], 'addToDispCalc', this.isCalcInDispatcherRate ? 1 : 0);
            this.$set(expenses[index], 'expenseType', this.expenseType);
            this.$set(expenses[index], 'timestamp', this.$moment.moment(this.expenseDate).format('X'));
          } else {
            expenses.push({
              driver: this.driver,
              truck: this.truck,
              timestamp: parseInt(new Date().getTime() / 1000),
              name: this.expensesName,
              paymentType: this.expensePaymentType,
              compensate: this.expenseCompensate,
              total: this.expensesValue,
              amount: _expensesCount,
              perItem: this.expensesPerItem,
              checkUPLOAD: this.checkUPLOAD,
              addToRate: this.isAddToRate ? 1 : 0,
              addToDispCalc: this.isCalcInDispatcherRate ? 1 : 0,
              pending: this.isFinished
            });
          }
          this.resetModalData();
          this.saveExpenses(expenses).then((response) => {
            this.editTimestamp = 0;
            this.addDialog = false;
            this.expenseType = 0;
          });
        }).catch(() => {
        });
      }
    },
    editExpense (timestamp, name) {
      const index = this.getExpenseIndexByTimestamp(timestamp, name);

      this.editTimestamp = timestamp;
      this.expenseDate = this.$moment.moment(timestamp * 1000).format('MM/DD/YYYY h:mm A');
      this.driver = this.expensesArray[index].driver || 0;
      this.truck = this.expensesArray[index].truck || (this.expensesArray[index].hasOwnProperty('dbData') ? this.expensesArray[index].dbData.truckId : 0);
      this.expensesName = this.expensesArray[index].name;
      this.expensePaymentType = this.expensesArray[index].paymentType;
      this.expenseCompensate = this.expensesArray[index].compensate;
      this.expensesValue = this.expensesArray[index].total;
      this.expensesCount = this.expensesArray[index].amount;
      this.expensesPerItem = this.expensesArray[index].perItem;
      this.checkUPLOAD = this.expensesArray[index].checkUPLOAD;
      this.checkUPLOADUrl = this.expensesArray[index].checkUPLOADUrl;
      this.isAddToRate = parseInt(this.expensesArray[index].addToRate) === 1;
      this.isCalcInDispatcherRate = parseInt(this.expensesArray[index].addToDispCalc) === 1;
      this.onExpenseNameSelected(true);
      this.driversListKey = 'driversListKey' + new Date().getTime();
      this.trucksListKey = 'trucksListKey' + new Date().getTime();
      this.addDialog = true;
    },
    onApproveAll () {
      let i = 0;
      if (!this.$root.isPendingExpensesApproveAllowed) return;
      this.$dialog.confirm(this.$root.t('Loads.ApproveExpense')).then((dialog) => {
        let expenses = this.expensesArray;
        for (i = 0; i < expenses.length; i++) {
          expenses[i].pending = false;
        }
        this.saveExpenses(expenses);
      });
    },
    approveExpense (timestamp, name) {
      if (!this.$root.isPendingExpensesApproveAllowed) return;
      this.$dialog.confirm(this.$root.t('Loads.ApproveExpense')).then((dialog) => {
        const index = this.getExpenseIndexByTimestamp(timestamp, name);
        let expenses = this.expensesArray;
        expenses[index].pending = false;
        this.saveExpenses(expenses);
      });
    },
    resetExpense (timestamp, name) {
      if (!this.$root.isPendingExpensesApproveAllowed) return;
      this.$dialog.confirm(this.$root.t('Loads.ResetExpense')).then((dialog) => {
        const index = this.getExpenseIndexByTimestamp(timestamp, name);
        let expenses = this.expensesArray;
        expenses[index].pending = true;
        this.saveExpenses(expenses);
      });
    },
    deleteExpense (timestamp, name) {
      const index = this.getExpenseIndexByTimestamp(timestamp, name);
      this.$dialog.confirm(this.$root.t('Loads.DeleteExpenseConfirmation')).then(dialog => {
        let expenses = this.expensesArray;
        expenses.splice(index, 1);
        this.saveExpenses(expenses);
      }).catch(() => {
      });
    },
    saveExpenses (expenses) {
      return new Promise((resolve, reject) => {
        this.$root.saveLoad({
          expensesJSON: JSON.stringify(expenses)
        }, this.loadId).then(() => {
          this.$emit('expensesSaved');
          this.updateDriversValues();
          resolve();
        });
      });
    },
    fileChange (fieldValue) {
      this.checkUPLOAD = fieldValue;
    },
    fetchData() {
      this.trucksFilterList = [{  id: -1, name: 'All' }];
      this.driversFilterList = [{  id: -1, fullName: 'All' }];
      this.$root.getTrucksList().then(response => {
        if (response.status === 'ok') {
          response.result.forEach(el => {
            this.trucksFilterList.push(el);
          });
        }
      });
      this.$root.loadUsersList(this.$store.getters.roleDriver, false, true).then(response => {
        if (response.status === 'ok') {
          response.result.data.forEach(el => {
            this.driversFilterList.push(el);
          });
        }
      });
    }
  }
};
</script>
