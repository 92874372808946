export default {
  data: function () {
  },
  methods: {
    getDocumentsList (page = 0, itemsPerPage = 10, user = 0, type = 'All', search = '') {
      return this.$root.get('/api/documents/list/' + page + '/' + itemsPerPage + '/' + user + '/' + type + '/' + search);
    },
    getDocumentByHash (hash) {
      return this.$root.get('/api/documents/getByHash/' + hash);
    },
  }
}
