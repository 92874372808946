<template>
  <v-container>
    <v-row width="100%" style="overflow: auto;">
      <v-card light :flat="print" style="width: 21cm !important; margin: auto;">
        <v-card-text>
          <v-row>
            <v-col xs="12" class="mt-1 pa-0">
              <v-row>
                <v-col xs="6" class="text-left pr-0 pl-4">
                  <img responsive width="80%" :src="logoPath"/>
                  <br/>
                  <div class="text-left" style="width: 80%;">
                    <span v-html="contactInfo ? $root.newLineToBR(contactInfo) : null"></span>
                  </div>
                </v-col>
                <v-col xs="6" class="pt-5" v-if="truckData">
                  <div style="border-left: 4px #999 solid" class="pl-2">
                    <v-layout>
                      <v-flex xs6 class="text-no-wrap">NAME</v-flex>
                      <v-flex xs6 class="text-no-wrap">{{truckData.name}}</v-flex>
                    </v-layout>
                    <v-divider/>
                    <v-layout>
                      <v-flex xs6 class="text-no-wrap">TYPE</v-flex>
                      <v-flex xs6 class="text-no-wrap">{{truckData.type}}</v-flex>
                    </v-layout>
                    <v-divider/>
                    <v-layout>
                      <v-flex xs6 class="text-no-wrap">LICENSE PLATE</v-flex>
                      <v-flex xs6 class="text-no-wrap">{{truckData.licensePlate}}</v-flex>
                    </v-layout>
                    <v-divider/>
                    <v-layout>
                      <v-flex xs6 class="text-no-wrap">VIN</v-flex>
                      <v-flex xs6 class="text-no-wrap">{{truckData.vin}}</v-flex>
                    </v-layout>
                    <v-divider/>
                    <v-layout>
                      <v-flex xs6 class="text-no-wrap">MODEL YEAR</v-flex>
                      <v-flex xs6 class="text-no-wrap">{{truckData.model}}&nbsp;{{truckData.year}}</v-flex>
                    </v-layout>
                    <v-divider/>
                    <v-layout>
                      <v-flex xs6 class="text-no-wrap">USDOT</v-flex>
                      <v-flex xs6 class="text-no-wrap">{{truckData.usdot}}</v-flex>
                    </v-layout>
                    <v-divider/>
                  </div>
                </v-col>
              </v-row>
              <h3 style="line-height: 32px;" class="text-center" v-html="title"></h3>
              <v-simple-table dense
                              style="border: 1px solid #000; background: transparent;"
                              class="text-left">
                <thead style="display: table-header-group;">
                  <tr style="background:#0090D3;color:#fff;page-break-inside: avoid;">
                    <th class="report_font_size_normal text-center white--text">&nbsp;I&nbsp;</th>
                    <th class="report_font_size_normal text-center white--text">R</th>
                    <th class="report_font_size_normal text-center white--text">M</th>
                    <th class="report_font_size_normal text-center white--text">Date<br/>MM/DD/YY</th>
                    <th class="report_font_size_normal text-center white--text">Mileage</th>
                    <th class="report_font_size_normal text-center white--text">Type</th>
                    <th class="report_font_size_normal text-center white--text" v-if="!isDOT && !$route.params.isDOT">Part</th>
                    <th class="report_font_size_normal text-center white--text">Next Due Service<br/>Date or mileage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in data.items" style="page-break-inside: avoid;" :key="'item'+item.id">
                    <td class="report_font_size_normal text-center"><template v-if="item.workType==='I'">X</template></td>
                    <td class="report_font_size_normal text-center"><template v-if="item.workType==='R'">X</template></td>
                    <td class="report_font_size_normal text-center"><template v-if="item.workType==='M'">X</template></td>
                    <td class="report_font_size_normal text-center">
                      {{$root.formatDate(item.maintenanceDATE)}}
                    </td>
                    <td class="pl-2 report_font_size_normal text-center">
                      <span v-if="!truckData">{{ item.truckName }}<br/></span>
                      {{item.miles.toLocaleString()}}{{$t('milesShort')}}
                    </td>
                    <td class="pl-2 report_font_size_normal">
                      {{item.maintenanceType }}
                      <p v-if="item.maintenanceType === 'Oil Replacement' && item.nextOilReplacementIntervalMiles">
                        <small>Each:&nbsp;{{ item.nextOilReplacementIntervalMiles }}{{ $t('milesShort') }}</small>
                      </p>


                      <template v-if="item.isAnnual === 1 || item.isQuaterly === 1">
                        <br/>
                        <small v-if="item.isAnnual === 1">{{ $t('Trucks.AnnualInspectionsAnnual') }}&nbsp;</small>
                        <small v-if="item.isQuaterly === 1">{{ $t('Trucks.AnnualInspectionsQuarterly') }}</small>
                      </template>

                    </td>
                    <td class="pl-2 report_font_size_normal" v-if="!isDOT && !$route.params.isDOT">
                      <template v-for="(part, index) in item.parts">
                        <template v-if="part.partName">&nbsp;{{part.partName}}</template>
                        <template v-if="part.partCode">&nbsp;({{part.partCode}})</template>
                        <template v-if="part.partAmount">&nbsp;x{{part.partAmount}}</template>
                        <template v-if="part.partWear">&nbsp;<b>{{$t('Trucks.maintenancePartWear')}}:</b>&nbsp;{{item.partWear}}</template>
                        <template v-if="part.partSize">&nbsp;<b>{{$t('Trucks.maintenancePartSize')}}:</b>&nbsp;{{item.partSize}}</template>
                        <template v-if="part.isReplaced"><br/>&nbsp;<b>{{$t('Trucks.maintenanceIsReplaced')}}:</b></template>
                        <template v-if="part.newPartName">&nbsp;{{part.newPartName}}</template>
                        <template v-if="part.newPartCode">&nbsp;({{part.newPartCode}})</template>
                        <template v-if="index < item.parts.length - 1">,</template>
                      </template>
                    </td>
                    <td class="report_font_size_normal text-center">
                      <template v-if="item.nextMaintenanceMiles && item.nextMaintenanceDATE">
                        {{item.nextMaintenanceMiles.toLocaleString()}}{{$t('milesShort')}}<br/>{{item.nextMaintenanceDATE}}
                      </template>
                      <template v-else-if="item.nextMaintenanceMiles">
                        {{item.nextMaintenanceMiles.toLocaleString()}}{{$t('milesShort')}}
                      </template>
                      <template v-else-if="item.nextMaintenanceDATE">
                        {{item.nextMaintenanceDATE}}
                      </template>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>

import reportsMixin from '../../mixins/reportsMixin.js';

export default {
  name: 'TruckMaintenanceReportTemplate',
  props: ['entries', 'totalsCounts', 'print', 'from', 'to', 'isDOT', 'companyId', 'reportData'],
  mixins: [reportsMixin],
  components: {},
  data: function () {
    return {
      byStatusCounts: {},
      publicPath: process.env.BASE_URL,
      contactInfo: '',
      truckData: null,
      logoPath: '',
      title: '',
      data: {}
    };
  },
  mounted () {
    this.$nextTick(() => {
      if (this.entries) {
        if (this.totalsCounts) {
          this.$set(this, 'data', this.totalsCounts);
        }
        this.$set(this.data, 'items', this.entries);
      } else  {
        this.$set(this, 'data', this.reportData);
      }

      this.$root.getInvoiceLogoPath(this.companyId).then(path => {
        this.logoPath = path;
      });
      this.$root.getGlobalSettingsField('invoiceContactInfo', this.companyId).then((response) => {
        if (response.status === 'ok') {
          this.contactInfo = response.result;
        }
      });

      if (this.data && this.data.hasOwnProperty('items') && this.data.items.length > 0) {
        let trucksMap = {};
        for (let i = 0; i < this.data.items.length; i++) {
          trucksMap[parseInt(this.data.items[i].truckId)] = 1;
        }
        if (Object.keys(trucksMap).length === 1) {
          this.$root.getTruck(this.data.items[0].truckId).then(response => {
            if (response.status === 'ok') {
              this.truckData = response.result;
            }
          });
        }
      }
      this.title = this.reportTitle();
      document.title = this.title;
    });
  },
  methods: {
    reportTitle () {
      let result = 'Vehicle Inspection, Repair and Maintenance Report: ';
      this.$root.currentReportFileName = "VehicleInspectionRepairAndMaintenanceReport_" + (parseInt(this.$route.params.report_number) > 0 ? '_' + this.$route.params.report_number : '') + '_';
      if (this.hasOwnProperty('data') && this.data.hasOwnProperty('items') && this.data.items.length > 0) {
        if (this.$root.formatDate(this.data.items[0].maintenanceDATE.substring(0, 10)) === this.$root.formatDate(this.data.items[this.data.items.length - 1].maintenanceDATE.substring(0, 10))) {
          result += this.$root.formatDate(this.data.items[0].maintenanceDATE.substring(0, 10));
          this.$root.currentReportFileName += this.$root.parseDate(this.data.items[0].maintenanceDATE.substring(0, 10));
        } else {
          result += this.$root.formatDate(this.data.items[0].maintenanceDATE.substring(0, 10)) + ' - ' + this.$root.formatDate(this.data.items[this.data.items.length - 1].maintenanceDATE.substring(0, 10));
          this.$root.currentReportFileName += this.$root.parseDate(this.data.items[0].maintenanceDATE.substring(0, 10)) + '-' + this.$root.parseDate(this.data.items[this.data.items.length - 1].maintenanceDATE.substring(0, 10));
        }
      }
      else if (this.hasOwnProperty('data') && this.data.hasOwnProperty('items') && this.data.items.length === 1) {
        result += this.$root.formatDate(this.data.items[0].maintenanceDATE.substring(0, 10));
        this.$root.currentReportFileName += this.$root.parseDate(this.data.items[0].maintenanceDATE.substring(0, 10));
      }
      return result;
    }
  }
};

</script>
